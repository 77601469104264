import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';

import { getCurrentLottery, getTicketPrice, getPurchasedTicketDetails } from 'utils/contracts';
import Table from "examples/Tables/Table";
import VuiBadge from "./VuiBadge";
import { formatDateTime } from "utils/format";
import { formatAddress } from "utils/format";
import SearchBar from './SearchBar';
import { getMdexTicketEvents } from "utils/contracts";

const EventTicketsHistory = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    async function fetchData() {
        //const lastTransactions = await getMdexTicketLogs()
        const transaction = await getMdexTicketEvents();
        const filterEvents = transaction ? transaction.filter(item =>
            item.event.includes('TicketsPurchased')
          ) : [];
        console.log(filterEvents);
        setData(filterEvents);
    }

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTickets = data ? data.filter(item =>
    item.returnValues.user.toLowerCase().includes(searchTerm.toLowerCase())||
    formatDateTime(item.returnValues.timestamp).toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];


//   const tableRow = () => {
//     return filteredTickets.map((item) => ({
//       Ticket: (),
//       UserID: (
//         <VuiBadge badgeContent={item.user.id} size={'lg'} />
//       ),
//       User: formatAddress(item.userAddress),
//       Date: formatDateTime(item.ticket.timestamp),
//     }));
//   };

  return (
    <Card >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Latest purchases
          </VuiTypography>
        </VuiBox>
        <VuiBox>
        {filteredTickets.length > 0 ? <SearchBar value={searchTerm} onChange={handleSearch} /> : <></>}
        </VuiBox>
      </VuiBox>
     
      <Grid container style={{ maxHeight: '75vh', overflowY: 'auto' }}>
        {/* {filteredTickets.length > 0 ?<Table
          columns={[
            { name: "Ticket", align: "left" },
            { name: "UserID", align: "left" },
            { name: "User", align: "left" },
            { name: "Date", align: "left" },
          ]}
          rows={tableRow()}
        />
        :
        <VuiTypography color='white' variant='button'>Be the first to choose tickets for the open draw.</VuiTypography>
    } */}
      </Grid>
    </Card>
  );
};

export default EventTicketsHistory;
