// ConnectWallet.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import VuiButton from "./VuiButton";
import maticIcon from "assets/images/matic.webp";
import Grid from "@mui/material/Grid";
import VuiTypography from './VuiTypography';
import VuiBox from './VuiBox';
import Detect, { getAccountWallet } from 'detect';
import Loader from './Loader';
import { Puff } from 'react-loading-icons';
import { formatBalance } from 'utils/format';
import { formatAddress } from 'utils/format';
import { checkAccountWallet } from 'detect';
import { Link } from '@mui/material';


const ConnectWallet = () => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleConnectWallet = async () => {
    setLoading(true);
    const accountData = await getAccountWallet();
    setAccount(accountData.account);
    setBalance(accountData.balance);
    setLoading(false);
  };

  const checkWalletConnection = async () => {
    const accountData = await checkAccountWallet();
    if (accountData) {
      setAccount(accountData.account);
      setBalance(accountData.balance);
    }
  };

  useEffect(() => {
    
    checkWalletConnection();

    const updateAccount = () => {
      console.log('update')
    };

    Detect(updateAccount);

  }, []);

  return (
    <>
      {account ? (
        <VuiBox
          color="white"
          bgColor="primary"
          variant="gradient"
          borderRadius="md"
          opacity={1}
          height={50}
        >
          <Grid container spacing={0} height={10} pl={1.7} pr={3} pt={0.8} m={0}>
            <Grid item md={'auto'} flexDirection='column' display={'flex'}>
              <VuiTypography color="white" variant="button" fontWeight="regular" mb='0px' sx={{ fontSize: '11px' }}>
                Balance
              </VuiTypography>
              <VuiTypography color='white' variant='button' mb='0px' mt='0px'>
                {formatBalance(balance)}
              </VuiTypography>
            </Grid>
            <Grid item md={'auto'} flexDirection='column' display={'flex'} ml={1.5}>
              <VuiTypography color="white" variant="button" fontWeight="regular" mb='0px' sx={{ fontSize: '11px' }}>
                Account
              </VuiTypography>
              <VuiTypography color='white' variant='button' mb='0px' mt='0px'>
                {formatAddress(account)}
              </VuiTypography>
            </Grid>
          </Grid>
        </VuiBox>
      ) : (
        window.ethereum ? 
        <VuiButton variant="gradient" color="info" onClick={handleConnectWallet}>
          {loading ? <Puff /> : 'Connect Wallet'}
        </VuiButton>
        :
        <Link href='http://metamask.io' target='_blank'>
          <VuiButton variant="gradient" color="warning">
            Install Metamask Wallet
          </VuiButton>
        </Link>
      )}
    </>
  );
};

export default ConnectWallet;
