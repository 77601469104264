import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const QuotePrice = () => {
  const currency = Cookies.get("currency") ?? 'USDT';
  const currencySign = Cookies.get("currencySign") ?? 'U$ ';

    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/price?symbol=MATIC"+currency
        );
        
        const maticPrice = parseFloat(response.data.price);

        //console.log('GET QUOTE: '+maticPrice);

        // Salva o preço no cookie com validade de 5 horas
        Cookies.set("maticPrice", maticPrice, { expires: 1 / 2.4 });
        Cookies.set("currencySign", currencySign, { expires: 1 / 2.4 });

      } catch (error) {
        console.error("Erro ao buscar a cotação do MATICUSDT:", error);
      }
  };

  // Verifica se o preço já está salvo nos cookies
  const cachedPrice = Cookies.get("maticPrice");
  if (!cachedPrice) {
    fetchPrice();
  }

  console.log('QUOTE COOKIE: '+cachedPrice);
  return cachedPrice;
  

};