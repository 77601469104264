import React from "react";
import { enqueueSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard"
import { IoCopy, IoOpenOutline } from "react-icons/io5";
import { formatAddress } from "utils/format";
import VuiBox from "./VuiBox";
import VuiTypography from "./VuiTypography";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "@mui/material";

const Copied = async () => {
    await enqueueSnackbar('Address copied!', {
      variant: 'success'
    });
}

export const AddressCopy  = ({address, txId = false}) => {

    return <VuiBox>
        <CopyToClipboard text={address} onCopy={() => Copied()}>
            <VuiTypography color='light' variant="body">{formatAddress(address)} <IoCopy /></VuiTypography>
        </CopyToClipboard>
        {txId ?
        <Link href={`${process.env.REACT_APP_EXPLORER_URL}/tx/${address}`} target='_blank'>
            <VuiTypography color='light' variant='body' sx={{marginLeft: '5px'}}><IoOpenOutline /></VuiTypography>
        </Link>
        :
        <Link href={`${process.env.REACT_APP_EXPLORER_URL}/address/${address}`} target='_blank'>
            <VuiTypography color='light' variant='body' sx={{marginLeft: '5px'}}><IoOpenOutline /></VuiTypography>
        </Link>
        }
        
    </VuiBox>
}