import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';
import { checkDirectsInQueue } from "utils/contracts";
import Puff from "react-loading-icons/dist/esm/components/puff";
import { Padding } from "@mui/icons-material";
import { IoCheckboxOutline, IoCheckmarkCircle, IoLockClosed, IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import VuiProgress from "./VuiProgress";

const LevelsUnlock = ({address, levels}) => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [data, setData] = useState([]);

  // Função para determinar se um nível está desbloqueado ou bloqueado
    const getLevelStatus = (l, directs) => {
        return <Grid container mb={'10px'}>
            <Grid item xs={6}>
                <VuiTypography color="white" variant="button">
                    {directs >= l && directs >= 3 ? <IoCheckmarkCircle style={{marginRight: '5px'}} /> : <IoLockClosed style={{marginRight: '5px'}}/>}
                    {l}º Level 
                    {directs >= l && directs >= 3 ? ' unlocked ' : ` locked `}
                </VuiTypography>
            </Grid>
            <Grid item xs={6} textAlign={'right'}>
                <VuiTypography color="white" variant="button">
                        {`${directs}/${l <= 3 ? 3 : l}`}
                </VuiTypography>
            </Grid>
            <Grid item xs={12}>
                <VuiProgress
                    value={100} 
                    variant="gradient" 
                    color={directs >= l && directs >= 3 ? 'success' : 'dark'}
                />
            </Grid>
            
            
        </Grid>
    };

  useEffect(() => {

    async function fetchData() {
      
        if (address){
            let totalsData = await checkDirectsInQueue(address);

            const levelsT = [];
            for (let i = 1; i <= levels; i++) {
                levelsT.push(getLevelStatus(i, totalsData));
            }

            setData(levelsT);
        }

    }

    fetchData();
  }, []);


  return <Card>
  <VuiBox display="flex"  justifyContent="space-between" alignItems="center" mb="32px">
    <VuiBox display='flex' flexDirection='column'>
        <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Unlock levels in the Spill Matrix
        </VuiTypography>
        <VuiTypography variant='button' color='text' fontWeight='regular' mb='20px'>                
            The more direct referrals you unlock earning levels in the Spill Matrix
		</VuiTypography>
    </VuiBox>
   
  </VuiBox>
 
  
    {data.length > 0 ?
        data.map((item, i) => (

        <Grid container key={i+1}>
            <Grid item xs={12}>
            {item}
            </Grid>
        </Grid>
        ))
        
        :
        <Puff />
    }
  
</Card>

}

export default LevelsUnlock;