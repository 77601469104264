import React, { useEffect, useState, useContext } from "react";
import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BuyTicket from "components/BuyTicket";

function BuyPage() {
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <BuyTicket />
      <Footer />
    </DashboardLayout>
  );
}

export default BuyPage;
