// detect.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from "@metamask/detect-provider";
import { enqueueSnackbar } from 'notistack';
import { ethers } from "ethers";

const CHAIN_ID = Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID);
const chain = {
  method: 'wallet_addEthereumChain',
  params: [{
    chainId: CHAIN_ID,
    rpcUrls: [process.env.REACT_APP_RPC_URL],
    chainName: process.env.REACT_APP_NETWORK_NAME,
    nativeCurrency: {
      name: process.env.REACT_APP_NETWORK_CURRENCY,
      symbol: process.env.REACT_APP_NETWORK_CURRENCY,
      decimals: 18,
    },
    blockExplorerUrls: [process.env.REACT_APP_EXPLORER_URL],
  }],
};

export const checkAccountWallet = async () => {
  
  const accounts = await window.ethereum
    .request({ method: "eth_accounts" })
    .catch((err) => {
      console.error("Error fetching accounts:", err);
      return [];
    });

  if (accounts.length === 0) {
    return null;
  }

  const balance = await getBalance(accounts[0]);
  return { balance, account: accounts[0] };
};

export const connectWalletCheck = async () => {
  const provider = await detectEthereumProvider();
  if (provider && provider === window.ethereum) {
    //console.log("MetaMask is available!");
    getAccount();
  } else {
    alert('You need to install MetaMask');
    enqueueSnackbar('You need to install MetaMask', { variant: 'error' });
  }

  if (provider !== window.ethereum) {
    console.error("Do you have multiple wallets installed?");
  }
};

const getBalance = async (account) => {
 
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  
  try {
    const balanceWei = await provider.getBalance(account);
    const balanceEth = Web3.utils.fromWei(balanceWei.toString(), 'ether');
    return balanceEth;
  } catch (error) {
    console.error("Error fetching balance:", error);
    return 0;
  }
};

export const getAccountWallet = async () => {

  const accounts = await window.ethereum
    .request({ method: "eth_requestAccounts" })
    .catch(async (err) => {
      if (err.code === 4001) {
        console.log("Please connect to MetaMask.");
        await enqueueSnackbar('Connect to MetaMask', { variant: 'error' });
      } else {
        console.error(err);
      }
    });

  if (accounts.length === 0) {
    return null;
  }

  const balance = await getBalance(accounts[0]);
  return { balance, account: accounts[0] };
};

export const checkNetworkWallet = async () => {

  const networkId = await window.ethereum.request({ method: "eth_chainId" });
  if (networkId.toString() !== CHAIN_ID) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN_ID }],
      });
      await enqueueSnackbar('Change your network to ' + process.env.REACT_APP_NETWORK_NAME, {
        variant: 'warning',
        persist: true,
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request(chain);
        } catch (addError) {
          console.error("Error adding network:", addError);
          await enqueueSnackbar('Error adding network', { variant: 'error', persist: true });
        }
      } else {
        console.error("Error switching network:", switchError);
        await enqueueSnackbar('Error switching network', { variant: 'error', persist: true });
      }
    }
    return false;
  }else{
    return true;
  }
};

const handleChanged = async (callback) => {
  await checkNetworkWallet();
  const account = await getAccountWallet();
  if (callback) {
    callback(account);
  }
};

const handleAccount = () => {
  console.log('change');
  return window.location.reload();
};

const Detect = async (callback) => {
  await checkNetworkWallet();

  if (window.ethereum) {
    window.ethereum.on("chainChanged", () => handleChanged(callback));
    window.ethereum.on("accountsChanged", () => handleAccount());
  } else {
    enqueueSnackbar('You need to install MetaMask', { variant: 'error' });
    console.error("MetaMask is not installed");
  }
};

export default Detect;
