import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';

import { getDoubleBackQueue, getDoubleBackQueueTotal, getDoubleBackBalance } from 'utils/contracts';
import Table from "examples/Tables/Table";
import VuiBadge from "./VuiBadge";
import { formatDateTime } from "utils/format";
import { formatAddress } from "utils/format";
import SearchBar from './SearchBar';
import Web3 from "web3";
import VuiProgress from "./VuiProgress";
import { AddressCopy } from "./AddressCopy";
import { checkAccountWallet } from "detect";

const DoubleBackQueue = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [balanceDB, setBalanceDB] = useState(0);
  const [account, setAccount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const totalQueue = await getDoubleBackQueueTotal();
      const getDoubleBackQueueData = await getDoubleBackQueue(startIndex, totalQueue - 1);

      // Add original index to each item
      const indexedData = getDoubleBackQueueData.map((item, index) => ({
        ...item,
        originalIndex: index
      }));

      console.log(indexedData);
      setData(indexedData);
      

      const getDoubleBackBalanceData = await getDoubleBackBalance();
      setBalanceDB(Web3.utils.fromWei(getDoubleBackBalanceData, 'ether'));
    }

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const checkWalletConnection = async () => {
    const accountData = await checkAccountWallet();
    if (accountData) {
      setAccount(accountData.account);
    }
  };

  useEffect(() => {
    checkWalletConnection();
  }, [account]);

  const normalizedAccount = account ? account.toLowerCase() : null;

  const filteredTickets = data ? data.filter(item =>
    item.user.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  const tableRow = () => {
    let lastBalance = balanceDB; // Initialize lastBalance outside the map loop

    const row = filteredTickets.map((item) => {
      
      // Calculate the remaining balance after each payout
      const payoutAmount = Web3.utils.fromWei(item.payoutAmount, 'ether');
      
      const remainingBalance = item.originalIndex === 0 ? lastBalance : lastBalance - payoutAmount;

      // Update lastBalance for subsequent iterations
      if (item.originalIndex > 0) {
        lastBalance = remainingBalance;
      }

      let Percent = (lastBalance / payoutAmount) * 100;

      return {
        Place: (
          <VuiBadge badgeContent={(item.originalIndex + 1) + 'º'} color={'primary'} size={'lg'} />
        ),
        Ticket: <Grid container spacing={3} ml="0px" mt="10px" style={{ height: '100%', overflow: 'auto' }}>
          <a
            key={item.ticketId}
            href={"#" + item.ticketId}
            className={"buttonTicket buttonTicketSelected"}
          >
            {item.refId}
          </a>
      </Grid>,
        User: <AddressCopy address={item.user} />,
        ToPay: (
          <VuiProgress
            value={Percent > 0 ? Percent : 0} // Calculate the percentage of remaining balance
            variant="gradient"
            label
          />
        )
      };
    });

    // Now, lastBalance holds the remaining balance after all payouts
    // You can use it for other calculations if needed

    return row; // Return the array of row data
  };

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Order Guaranteed Return
          </VuiTypography>
        </VuiBox>
        <VuiBox>
          {data.length > 0 ? <SearchBar value={searchTerm} onChange={handleSearch} /> : <></>}
        </VuiBox>
      </VuiBox>

      <Grid container>
        {data.length > 0 ? <Table
          columns={[
            { name: "Place", align: "left" },
            { name: "Ticket", align: "left" },
            { name: "User", align: "left" },
            { name: "ToPay", align: "left" },
          ]}
          rows={tableRow()}
        />
          :
          <VuiTypography color='white' variant='button'>No payment in line, buy your ticket and be the next to receive the 200% Guaranteed Return</VuiTypography>
        }
      </Grid>
    </Card>
  );
};

export default DoubleBackQueue;
