import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';

import { getCurrentLottery, getTicketPrice, getPurchasedTicketDetails } from 'utils/contracts';
import Table from "examples/Tables/Table";
import VuiBadge from "./VuiBadge";
import { formatDateTime } from "utils/format";
import { formatAddress } from "utils/format";
import SearchBar from './SearchBar';
import { getUserTickets } from "utils/contracts";
import { Puff } from "react-loading-icons";
import VuiButton from "./VuiButton";
import { TicketBadge } from "./VuiBadge/ticketBadge";

const UserTicketsHistory = ({userAddress, lottoId, tickets}) => {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const tableRow = () => {
    return tickets.map((item) => ({
      Ticket: (
        <Grid container spacing={3} ml="0px" mt="10px" style={{ height: '100%', overflow: 'auto' }}>
                  <a
                    key={item.ticketNumber}
                    href={"#" + item.ticketNumber}
                    className={"buttonTicket buttonTicketSelected"}
                  >
                    {item.ticketNumber}
                  </a>
              </Grid>
      ),
      DrawId: (
        <VuiBadge badgeContent={item.lotteryId} size={'lg'} />
      ),
      Date: formatDateTime(item.timestamp),
    }));
  };

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
      <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Lucky tickets
          </VuiTypography>
        </VuiBox>
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Draw # {lottoId ?  lottoId : ''}
          </VuiTypography>
        </VuiBox>

      </VuiBox>
     
      <Grid container style={{ maxHeight: '75vh', overflowY: 'auto' }}>
       {tickets.length > 0 ?<Table
          columns={[
            { name: "Ticket", align: "left" },
            { name: "DrawId", align: "left" },
            { name: "Date", align: "left" },
          ]}
          rows={tableRow()}
        />
        :
        <VuiBox>
            <VuiTypography color='white'  variant='button' display='block'>This user not have any tickets for Draw Nº {lottoId ? lottoId : ''}</VuiTypography>
            <VuiButton color='success' href='/buy' variant='contained' size={'medium'} display='block'>Select your tickets now</VuiButton>
        </VuiBox>
    }
      </Grid>
    </Card>
  );
};

export default UserTicketsHistory;
