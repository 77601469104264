import React, { useEffect, useState } from "react";

import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/cardimgfree.png";

import Puff from "react-loading-icons/dist/esm/components/puff";
import { formatAddress } from "utils/format";
import { IoCopy, IoHelp, IoHelpCircle } from "react-icons/io5";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { enqueueSnackbar } from "notistack";
import { AddressCopy } from "components/AddressCopy";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { setAutoPurchase } from "utils/contractInteract";
import { getAccountWallet } from "detect";
import { checkAccountWallet } from "detect";

const WelcomeMark = ({id, nickname, address, sponsor, autoPurchase = false}) => {

  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [autoPurchaseState, setautoPurchaseState] = useState(null);

  const personalLink = `${window.location.origin}?refId=${id}`
  const Copied = async () => {
    await enqueueSnackbar('Link copied!', {
      variant: 'success'
    });
  }

  const ActiveAutoPurchase = async (status) => {

    setLoading(true);
    const account = await getAccountWallet();
    const activate = await setAutoPurchase(status, account.account);
     
    setLoading(false);

    if (activate){
      setautoPurchaseState(status);
    }
    
  }


  const checkWalletConnection = async () => {
    const accountData = await checkAccountWallet();
    if (accountData) {
      setAccount(accountData.account);
    }
  };

  
  useEffect(() => {
    setautoPurchaseState(autoPurchase);

    if (window.ethereum.isConnected()) {
      checkWalletConnection();
    } else {
      setAccount(null);
    }

    
    
  }, [autoPurchase, account, address]);

  const normalizedAddress = address ? address.toLowerCase() : null;
  const normalizedAccount = account ? account.toLowerCase() : null;
  
  return (
    <Card sx={() => ({
      height: "340px",
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%"
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Welcome,
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="10px">
          {id ? 'ID #'+id : <Puff />}
          </VuiTypography>
          
          <VuiTypography color="text" variant="button" fontWeight="regular" sx={{lineHeight: '1px'}}> Automatic repurchase: 
            <VuiTypography color={autoPurchaseState ? 'success' : 'error'}  variant="button" fontWeight="bold"> {autoPurchaseState ? 'Activated' : 'Disabled'} </VuiTypography> 
          </VuiTypography>

          
          {normalizedAddress === normalizedAccount ?
            <VuiButton onClick={() => ActiveAutoPurchase(!autoPurchaseState)} color={autoPurchaseState ? 'error' : 'success'}>
              {autoPurchaseState ? 'Disable automatic repurchase' : 'Enable automatic repurchase'}
            </VuiButton>
            :
            null
          }


          

          {nickname ? 
          <VuiTypography color="text" variant="button" fontWeight="regular" sx={{lineHeight: '1px'}}> Nickname: 
            <VuiTypography color="white" variant="" fontWeight="bold"> {nickname} </VuiTypography> 
          </VuiTypography> : ''} 

          {address ? 
            <VuiTypography color="text" variant="button" display='block' fontWeight="regular" mb="0px"> Address:
              <VuiTypography color="white" variant="" fontWeight="bold" mb="10px"> <AddressCopy address={address} /> </VuiTypography> 
            </VuiTypography>
            : ''} 

        {sponsor ? 
            <VuiTypography color="text" variant="button" display='block' fontWeight="regular" mb="0px" > Invited by:
              <VuiTypography color="white" variant="" fontWeight="bold" mb="10px"> <AddressCopy address={sponsor} /> </VuiTypography> 
            </VuiTypography>
            : ''} 
          

          
          
        </VuiBox>
          {id ? 
          <VuiTypography
          component="a"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },
          }}
        >
          <VuiTypography color="text" variant="button" display='block' fontWeight="regular" mb="0px"> Personal link:
            <CopyToClipboard text={personalLink} onCopy={() => Copied()}>
              <VuiTypography color="white" display='block' variant="" fontWeight="bold" mb="10px"> {personalLink} <IoCopy /> </VuiTypography>
            </CopyToClipboard>
          </VuiTypography>
        </VuiTypography>
        :<></>
        }
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
