import MDexTicket from '../abis/MDexTicket.json';
import MDex from '../abis/MDex.json';
import DoubleBack from '../abis/DoubleBack.json';
import { useState } from 'react';
import Web3 from 'web3';
import { enqueueSnackbar } from 'notistack';

const web3 = new Web3(process.env.REACT_APP_INFURA_WEBSOCKET_URL);
//const web3 = new Web3(window.ethereum);

import Addresses from '../abis/addresses.json';


const chainId = process.env.REACT_APP_CHAIN_ID;

const MDexTicket_ABI = MDexTicket.abi;
const MDexTicket_ADDRESS = Addresses[chainId]?.MDexTicket || null;

const DOUBLEBACK_ABI = DoubleBack.abi;
const DOUBLEBACK_ADDRESS = Addresses[chainId]?.DoubleBack || null;

const MDex_ABI = MDex.abi;
const MDex_ADDRESS = Addresses[chainId]?.MDex || null;

if (!MDexTicket_ADDRESS || !DOUBLEBACK_ADDRESS || !MDex_ADDRESS) {
  throw new Error(`No contract addresses found for chain ID: ${chainId}`);
}

const contractMDexTicket = new web3.eth.Contract(MDexTicket_ABI, MDexTicket_ADDRESS);
export const contractMDex = new web3.eth.Contract(MDex_ABI, MDex_ADDRESS);
export const contractDoubleBack = new web3.eth.Contract(DOUBLEBACK_ABI, DOUBLEBACK_ADDRESS);

const formatAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 5)}...${address.substring(address.length - 3)}`;
  };

export const contractEvents = async () => {
    (async () => {
        
      eventsMdexTicket();
      
      
    })();
}

export const eventsMdexTicket = () => {

  contractMDexTicket.events['TicketsPurchased','LotteryStarted','LotteryResult']({}, async (error, event) => {
    if (error) {
        console.error("Error:", error);
        return;
    }
    console.log("New event received:");
    console.log(event.returnValues);

    if (event.event == 'TicketsPurchased'){
      await enqueueSnackbar(formatAddress(event.returnValues.user)+' purchased the ticket '+event.returnValues.ticketId, {
          variant: 'info'
        });
    }

    if (event.event == 'LotteryResult'){
      // await enqueueSnackbar(formatAddress(event.returnValues.winner)+' won the draw with the ticket'+event.returnValues.ticketId, {
      //     variant: 'success'
      //   });
    }

    if (event.event == 'LotteryStarted'){
      // await enqueueSnackbar('New draw started!', {
      //     variant: 'success'
      //   });
    }

  })
  .on("connected", async () => {
      // await enqueueSnackbar('Connected to the blockchain', {
      // variant: 'success'
      // });
  })
  .on("changed", (event) => {
      console.log("Event changed:", event.returnValues);
  })
  .on("error", (error) => {
      console.error("Event error:", error);
  });

  return event;
}

export const eventsMdex = () => {

  contractMDex.events['TicketsPurchased','LotteryStarted','LotteryResult']({}, async (error, event) => {
    if (error) {
        console.error("Error:", error);
        return;
    }
    console.log("New event received:");
    console.log(event.returnValues);

    if (event.event == 'TicketsPurchased'){
      await enqueueSnackbar(formatAddress(event.returnValues.user)+' purchased the ticket '+event.returnValues.ticketId, {
          variant: 'info'
        });
    }

    if (event.event == 'LotteryResult'){
      // await enqueueSnackbar(formatAddress(event.returnValues.winner)+' won the draw with the ticket'+event.returnValues.ticketId, {
      //     variant: 'success'
      //   });
    }

    if (event.event == 'LotteryStarted'){
      // await enqueueSnackbar('New draw started!', {
      //     variant: 'success'
      //   });
    }

  })
  .on("connected", async () => {
      // await enqueueSnackbar('Connected to the blockchain', {
      // variant: 'success'
      // });
  })
  .on("changed", (event) => {
      console.log("Event changed:", event.returnValues);
  })
  .on("error", (error) => {
      console.error("Event error:", error);
  });

  return event;
}


