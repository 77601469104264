import Cookies from "js-cookie";
import Web3 from "web3";

export const formatAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 5)}...${address.substring(address.length - 4)}`;
  };
  
  export const formatBalance = (balance) => {
    if (!balance || balance === 0) return '0.000000';
    return parseFloat(balance).toFixed(6);
};

export function isAddress(addr){
  return Web3.utils.isAddress(addr);
}

export function formatCurrency(value) {
  // Converte o valor para um número com duas casas decimais
  const formattedValue = Number(value).toFixed(2);
  return `${Cookies.get('currencySign')} ${formattedValue}`;
}

export function getNativeCoin() {
  return process.env.REACT_APP_NETWORK_CURRENCY;
}

export function fromWei(value){
  return Web3.utils.fromWei(value.toString(), 'ether');
}

export function formatWei(value){
  return Web3.utils.fromWei(value.toString(), 'ether') + " " + process.env.REACT_APP_NETWORK_CURRENCY;
}

export function isZeroAddress(addr){
  return addr == '0x0000000000000000000000000000000000000000' ? true : false;
}

export function maticToCurrency(value){
  const convert = Number(Cookies.get('maticPrice') * value).toFixed(2);
  return `${Cookies.get('currencySign')} ${convert}`;
}

export const formatDateTime = (dateTime) => {
    // Converta o timestamp para milissegundos
    const date = new Date(dateTime * 1000);

    // Formate a data usando métodos do objeto Date
    const formattedDate = date.toLocaleDateString(); // 'dd/mm/yyyy'
    const formattedTime = date.toLocaleTimeString(); // 'hh:mm:ss'
    const formattedDateTime = date.toLocaleString(); // 'dd/mm/yyyy, hh:mm:ss'

    return formattedDateTime;
};