import React from "react";
import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/background_lotto_tickets.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import VuiButton from "./VuiButton";
import { maticToCurrency, fromWei } from "utils/format";
import VuiBadge from "./VuiBadge";

const UserLottoTickets = ({ lotto, tickets }) => {
  // Limitar a quantidade de tickets exibidos
  const maxTicketsToShow = 5;
  const extraTickets = tickets.length - maxTicketsToShow;
  const displayedTickets = tickets.slice(0, maxTicketsToShow);

  return (
    <Card sx={() => ({
      height: "240px",
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%"
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="white" variant="h5" fontWeight="regular" mb="0px" lineHeight="1px">
            {lotto ? maticToCurrency(fromWei(lotto.prize)) : 0} draw
          </VuiTypography>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="0px">
            Your tickets for this draw
          </VuiTypography>
          <VuiBox width="100%" display="flex" flexDirection="row" flexWrap="wrap">
            {displayedTickets.map((item) => (
              <a
                key={item.ticketNumber}
                href={"#" + item.ticketNumber}
                className={"buttonTicket buttonTicketSelected"}
                style={{ margin: '4px' }} // Adicionado margem para espaçamento
              >
                {item.ticketNumber}
              </a>
            ))}
            {extraTickets > 0 && (
              <VuiBadge badgeContent={`+${extraTickets} tickets`} container size={'xs'} color={'warning'} circular={true}/>
            )}
          </VuiBox>
        </VuiBox>
        <NavLink to={`/dashboard`}>
          <VuiButton color='primary' variant='contained' size={'medium'} >
            Show draw
          </VuiButton>
        </NavLink>
      </VuiBox>
    </Card>
  );
};

export default UserLottoTickets;
