/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { Card, Stack, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import GreenLightning from 'assets/images/shapes/green-lightning.svg';
import maticIcon from 'assets/images/matic.webp';
import WhiteLightning from 'assets/images/shapes/white-lightning.svg';
import linearGradient from 'assets/theme/functions/linearGradient';
import colors from 'assets/theme/base/colors';

import prizeIcon from 'assets/images/shapes/SVG/prie_w.svg';
import ticketIcon from 'assets/images/shapes/SVG/ticket_w.svg';
import cashIcon from 'assets/images/shapes/SVG/cash_hand_w.svg';

import LineChart from 'examples/Charts/LineCharts/LineChart';
import { lineChartDataProfile1, lineChartDataProfile2 } from 'variables/charts';
import { lineChartOptionsProfile2, lineChartOptionsProfile1 } from 'variables/charts';
import CircularProgress from '@mui/material/CircularProgress';
import web3 from '../utils/web3';
import { getCurrentLottery, getTicketPrice } from 'utils/contracts';
import VuiButton from "components/VuiButton";
import Cookies from "js-cookie";
import VuiProgress from "./VuiProgress";
import { formatCurrency } from "utils/format";
import gif from "assets/images/background_lotto_tickets.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getMdexTicketEvents } from "utils/contracts";


const LotteryInfo = React.memo(({ account }) => {
	const { gradients, info } = colors;
	const { cardContent } = gradients;
	const [currentLottery, setCurrentLottery] = useState(null);
    const [ticketPrice, setTicketPrice] = useState(0);

	useEffect(() => {
        async function fetchData() {
			const ticketPrice = await getTicketPrice();
			setTicketPrice(ticketPrice);
	
			const currentLotteryData = await getCurrentLottery();
			setCurrentLottery(currentLotteryData);

			const events = await getMdexTicketEvents();
			
        }

        fetchData();
    }, []);

	return (
		<Card
			sx={({ breakpoints }) => ({
				backgroundImage: `url(${gif})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
				[breakpoints.up('xxl')]: {
					maxHeight: '400px',
				}
			})}>
			<VuiBox display='flex' flexDirection='column'>
				<VuiTypography variant='lg' color='white' fontWeight='bold' mb='6px'>
					Lucky Number Bet
				</VuiTypography>
				<VuiTypography variant='button' color='text' fontWeight='regular' mb='30px'>
					Each Ticket guarantees a 200% return and a lucky number to compete in the draw.<br></br>
				</VuiTypography>
				
				<Stack
					spacing='24px'
					background='#fff'
					sx={({ breakpoints }) => ({
						[breakpoints.up('sm')]: {
							flexDirection: 'column'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row'
						},
						[breakpoints.only('xl')]: {
							flexDirection: 'column'
						}
					})}>
					
					<Grid
						container
						sx={({ breakpoints }) => ({
							spacing: '15px',
							[breakpoints.only('sm')]: {
								columnGap: '0px',
								rowGap: '15px'
							},
							[breakpoints.up('md')]: {
								gap: '14px',
								ml: '20px !important'
							},
							[breakpoints.only('xl')]: {
								gap: '12px',
								mx: 'auto !important'
							}
						})}>
							<Grid item md={'auto'} alignContent={'center'}>
									<VuiBox alignItems='center'>
										<VuiTypography color='text' variant='caption' fontWeight='medium' style={{marginTop:'0px'}}>
											Draw Prize
										</VuiTypography>
										<h1 className="text-gradient-gold" style={{marginTop:'-15px'}}>
											{currentLottery ? web3.utils.fromWei(currentLottery.prize, 'ether') : '--' } {web3.coin}
										</h1>
										<VuiTypography color='white' fontWeight='bold' style={{marginTop:'-15px'}}>
											{currentLottery ? formatCurrency(Cookies.get('maticPrice') * web3.utils.fromWei(currentLottery.prize, 'ether')) : '--' }
										</VuiTypography>
									</VuiBox>
							</Grid>

							<Grid item md={'auto'} alignContent={'center'}>
								<NavLink to='/buy'>
									<VuiButton color='success'  variant='contained' size={'medium'}>Buy Ticket Now</VuiButton>
								</NavLink>
							</Grid>

							<Grid item xs={12} sm={12} alignContent={'center'} mt={'5px'} mb={'0px'}>
								<VuiProgress 
									value={currentLottery ? Number(currentLottery.ticketCount) / (Number(currentLottery.ticketCount) + Number(currentLottery.ticketAvailable)) * 100 : 0 } 
									variant="gradient"/>
								<Grid container>
									<Grid item xs={6}>
										<VuiTypography color='white' variant='button'>
											{currentLottery ? Number(currentLottery.ticketCount) : 0} sold
										</VuiTypography>
									</Grid>

									<Grid item xs={6} style={{textAlign: 'right'}}>
										<VuiTypography color='white' variant='button'>
										{currentLottery ? Number(currentLottery.ticketAvailable) : 0} avaiable
										</VuiTypography>
									</Grid>
								</Grid>
							</Grid>

						

						<Grid item xs={12} md={5.5} xl={5.8} xxl={5.5}>
							<VuiBox
								display='flex'
								p='18px'
								alignItems='center'
								sx={{
									background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
									minHeight: '110px',
									borderRadius: '20px'
								}}>
								<VuiBox display='flex' flexDirection='column' mr='auto'>
									<VuiTypography color='text' variant='caption' fontWeight='medium' mb='2px'>
										Ticket Price
									</VuiTypography>
									<VuiTypography
										color='white'
										variant='h4'
										fontWeight='bold'
										sx={({ breakpoints }) => ({
											[breakpoints.only('xl')]: {
												fontSize: '20px'
											}
										})}>
										{ticketPrice ? web3.utils.fromWei(ticketPrice, 'ether') : '--' } {web3.coin}
									</VuiTypography>
									<VuiTypography
										variant='h6'
										color={'white'}
										fontWeight='bold'
										sx={({ breakpoints }) => ({
											[breakpoints.only('xl')]: {
												fontSize: '20px'
											}
										})}>
										{currentLottery ? formatCurrency(web3.utils.fromWei(ticketPrice, 'ether') * Cookies.get('maticPrice')) : '--' }
									</VuiTypography>
								</VuiBox>
								<VuiBox
									display='flex'
									justifyContent='center'
									alignItems='center'
									sx={{
										background: info.main,
										borderRadius: '12px',
										width: '56px',
										height: '56px'
									}}>
									<VuiBox component='img' src={ticketIcon} height='60%' />
								</VuiBox>
							</VuiBox>
						</Grid>

						<Grid item xs={12} md={5.5} xl={5.8} xxl={5.5}>
							<VuiBox
								display='flex'
								p='18px'
								alignItems='center'
								sx={{
									background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
									minHeight: '110px',
									borderRadius: '20px'
								}}>
								<VuiBox display='flex' flexDirection='column' mr='auto'>
									<VuiTypography color='success' variant='caption' fontWeight='medium' mb='2px'>
										Guaranteed earnings back
									</VuiTypography>
									<VuiTypography
										color='success'
										variant='h4'
										fontWeight='bold'
										sx={({ breakpoints }) => ({
											[breakpoints.only('xl')]: {
												fontSize: '20px'
											}
										})}>
										{ticketPrice ? web3.utils.fromWei(ticketPrice, 'ether') * 2 : '--' } {web3.coin}
									</VuiTypography>
									<VuiTypography
										variant='h6'
										fontWeight='bold'
										color={'white'}
										sx={({ breakpoints }) => ({
											[breakpoints.only('xl')]: {
												fontSize: '20px'
											}
										})}>
										{ticketPrice ? formatCurrency((web3.utils.fromWei(ticketPrice, 'ether') * 2) * Cookies.get('maticPrice')) : '--' } (2x)
									</VuiTypography>
								</VuiBox>
								<VuiBox
									display='flex'
									justifyContent='center'
									alignItems='center'
									sx={{
										background: info.main,
										borderRadius: '12px',
										width: '56px',
										height: '56px'
									}}>
									<VuiBox component='img' src={cashIcon} height='60%' />
								</VuiBox>
							</VuiBox>
						</Grid>
						
					</Grid>
				</Stack>
			</VuiBox>
		</Card>
	);
});

export default LotteryInfo;
