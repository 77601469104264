import React, { useEffect, useState, useContext } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getMdexTicketLogs } from "utils/contracts";
import { getTransactionDetails } from "utils/contracts";
import { getAllContractEvents } from "utils/contracts";
import { getEventsMDex } from "utils/contracts";
import { getMdexTicketEvents } from "utils/contracts";
import { Grid } from "@mui/material";
import EventTicketsHistory from "components/EventTicketsHistory";
import EventMdexHistory from "components/EventMdexHistory";


function ContractPage() {


  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container>
            <Grid item sm={12} md={12}>
                <EventMdexHistory />
            </Grid>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default ContractPage;
