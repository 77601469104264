/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { Card, Stack, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import GreenLightning from 'assets/images/shapes/green-lightning.svg';
import maticIcon from 'assets/images/matic.webp';
import WhiteLightning from 'assets/images/shapes/white-lightning.svg';
import linearGradient from 'assets/theme/functions/linearGradient';
import colors from 'assets/theme/base/colors';

import prizeIcon from 'assets/images/shapes/SVG/prie_w.svg';
import ticketIcon from 'assets/images/shapes/SVG/ticket_w.svg';
import cashIcon from 'assets/images/shapes/SVG/cash_hand_w.svg';

import LineChart from 'examples/Charts/LineCharts/LineChart';
import { lineChartDataProfile1, lineChartDataProfile2 } from 'variables/charts';
import { lineChartOptionsProfile2, lineChartOptionsProfile1 } from 'variables/charts';
import CircularProgress from '@mui/material/CircularProgress';
import web3 from '../utils/web3';
import { getCurrentLottery, getTicketPrice } from 'utils/contracts';
import VuiButton from "components/VuiButton";
import Cookies from "js-cookie";
import VuiProgress from "./VuiProgress";
import { formatCurrency } from "utils/format";
import BGWinner from "assets/images/background_winner.gif"
import ConfettiExplosion from 'react-confetti-explosion';
import { getPastLotteries } from "utils/contracts";
import { fromWei } from "utils/format";
import { maticToCurrency } from "utils/format";
import VuiBadge from "./VuiBadge";
import { formatAddress } from "utils/format";
import UserAddressComponent from "./UserAddressComponent";

const LastWinner = () => {
	const { gradients, info } = colors;
	const { cardContent } = gradients;
	const [currentLottery, setCurrentLottery] = useState(null);
	const [pastLottery, setPastLottery] = useState(null);
    const [ticketPrice, setTicketPrice] = useState(0);
    const [isExploding, setIsExploding] = React.useState(false);

	useEffect(() => {
        async function fetchData() {
			const ticketPrice = await getTicketPrice();
			setTicketPrice(ticketPrice);
	
			const currentLotteryData = await getCurrentLottery();
			setCurrentLottery(currentLotteryData);

            const pastLotteryData = await getPastLotteries();
            
            setPastLottery(pastLotteryData[pastLotteryData.length - 1 ]);


        }

        fetchData();
    }, []);

    const bigExplodeProps = {
        force: 0.9,
        duration: 10000,
        particleCount: 400
    };
      
	return pastLottery ? (
		<Card sx={() => ({
            py: "32px",
            backgroundImage: `url(${BGWinner})`,
            backgroundSize: "cover",
            backgroundPosition: "50%"
          })}>
			<VuiBox display='flex' flexDirection='column'>
            <Stack spacing='24px' background='#fff'
					sx={({ breakpoints }) => ({
						[breakpoints.up('sm')]: {
							flexDirection: 'column'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row'
						},
						[breakpoints.only('xl')]: {
							flexDirection: 'column'
						}
			})}>

                    <Grid container>
                        <Grid item sm={6}>
                            <Grid container >

                                <Grid item sm={12}>
                                    <VuiTypography variant='h3' color="white">Result of the last draw # {pastLottery ? pastLottery.id : ''}</VuiTypography>
                                </Grid>

                                <Grid item sm={6}>
                                    <VuiTypography color="white">Ticket Result</VuiTypography>
                                    <Grid container spacing={3} ml="0px" mt="10px" style={{ height: '100%', overflow: 'auto' }}>
                                        <a className="buttonTicket buttonTicketSelected"> {pastLottery ? pastLottery.ticketWinner : ''}</a>
                                    </Grid>

                                </Grid>

                                <Grid item sm={4}>
                                    <VuiTypography color="white">Winning User</VuiTypography>
                                    <VuiTypography color="white">{pastLottery ? <UserAddressComponent address={pastLottery.winner} /> : <></>}</VuiTypography>
                                </Grid>

                                <Grid item sm={4}>
                                    
                                </Grid>
                            
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <VuiTypography color="white">Award received </VuiTypography>
                            <h1 className="text-gradient-gold" style={{marginTop:'-15px'}}>
								{pastLottery ? maticToCurrency(fromWei(pastLottery.prize)) : '--' }
                            </h1>
                            <VuiTypography fontWeight='bold' color='white' style={{marginTop:'-15px'}}>
											{currentLottery ? web3.utils.fromWei(currentLottery.prize, 'ether') : '--' } {web3.coin}
							</VuiTypography>
						</Grid>
                    </Grid>

				</Stack>
			</VuiBox>
		</Card> )
        :
        <></>
    };

export default LastWinner;
