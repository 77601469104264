import web3 from './web3';
import MDexTicket from '../abis/MDexTicket.json';
import MDex from '../abis/MDex.json';
import DoubleBack from '../abis/DoubleBack.json';
import Addresses from '../abis/addresses.json';
import Cookies from 'js-cookie';

const chainId = process.env.REACT_APP_CHAIN_ID;

const MDexTicket_ABI = MDexTicket.abi;
const MDexTicket_ADDRESS = Addresses[chainId]?.MDexTicket || null;

const DOUBLEBACK_ABI = DoubleBack.abi;
const DOUBLEBACK_ADDRESS = Addresses[chainId]?.DoubleBack || null;

const MDex_ABI = MDex.abi;
const MDex_ADDRESS = Addresses[chainId]?.MDex || null;

if (!MDexTicket_ADDRESS || !DOUBLEBACK_ADDRESS || !MDex_ADDRESS) {
  throw new Error(`No contract addresses found for chain ID: ${chainId}`);
}

const contractMDexTicket = new web3.eth.Contract(MDexTicket_ABI, MDexTicket_ADDRESS);
export const contractMDex = new web3.eth.Contract(MDex_ABI, MDex_ADDRESS);
export const contractDoubleBack = new web3.eth.Contract(DOUBLEBACK_ABI, DOUBLEBACK_ADDRESS);

export default contractMDexTicket;

const decodeLog = (log) => {
  let decodedLog = null;
  let abi;

  console.log('log.topics[0]', log.topics[0]);

  if (log.address === MDexTicket_ADDRESS) {
    abi = MDexTicket_ABI.find(abi => abi.type === 'event' && web3.utils.sha3(abi.signature) === log.topics[0]);
  } else if (log.address === MDex_ADDRESS) {
    abi = MDex_ABI.find(abi => abi.type === 'event' && web3.utils.sha3(abi.signature) === log.topics[0]);
  } else if (log.address === DOUBLEBACK_ADDRESS) {
    abi = DOUBLEBACK_ABI.find(abi => abi.type === 'event' && web3.utils.sha3(abi.signature) === log.topics[0]);
  }

  if (abi) {
    decodedLog = web3.eth.abi.decodeLog(abi.inputs, log.data, log.topics.slice(1));
  } else {
    console.error("Evento não encontrado na ABI para o log:", log);
  }

  return decodedLog;
};

const listTransfersFromLogs = (logs) => {
  console.log("web3.utils.sha3('Transfer(address,address,uint256)')", web3.utils.sha3('Transfer(address,address,uint256)'));
  
  return logs.map(log => {
    console.log("log.topics[0]", log.topics[0]);
    
    if(log.topics[0] === web3.utils.sha3('Transfer(address,address,uint256)')){
      const decodedLog = decodeLog(log);
      return {
        from: decodedLog.from,
        to: decodedLog.to,
        value: web3.utils.fromWei(decodedLog.value, 'ether') // Convertendo de wei para ether
      };
    }
      
  });
};

// Função para obter a assinatura da função buyTickets
const getBuyTicketsSignature = () => {
  return web3.utils.sha3('buyTickets(uint256[],uint256,address)').slice(0, 10); // 0x seguido pelos primeiros 8 caracteres do hash
};

// Função para obter os detalhes da transação
export const getTransactionDetails = async (transactionHash) => {
  try {
    const transaction = await web3.eth.getTransaction(transactionHash);
    const receipt = await web3.eth.getTransactionReceipt(transactionHash);
    console.log('receipt', receipt);

    // Verificar se a transação chamou a função buyTickets
    const buyTicketsSignature = getBuyTicketsSignature();
    console.log('buyTicketsSignature', buyTicketsSignature);

    

    const isBuyTicketsCall = transaction.input.startsWith(buyTicketsSignature);

    console.log('isBuyTicketsCall', isBuyTicketsCall);

    if (!isBuyTicketsCall) {
      return null; // Não é uma chamada da função buyTickets
    }

    // Listar transferências associadas à transação
    const transfers = listTransfersFromLogs(receipt.logs);
    console.log('transfers', transfers);

    
    return {
      transaction,
      receipt,
      transfers
    };
  } catch (error) {
    console.error("Erro ao obter detalhes da transação:", error);
    return null;
  }
};

// Função para buscar transações internas e listar transferências
export const getBuyTicketsTransactions = async (userAddress, fromBlock = 0, toBlock = 'latest') => {
  try {
    const currentBlock = await web3.eth.getBlockNumber();
    const transactionHashes = [];

    // Buscar transações nos blocos recentes
    for (let i = currentBlock; i >= currentBlock - 1000; i--) {
      const block = await web3.eth.getBlock(i, true);
      block.transactions.forEach(tx => {
        if (tx.to === userAddress || tx.from === userAddress) {
          transactionHashes.push(tx.hash);
        }
      });
    }

    // Obter detalhes das transações e filtrar as chamadas da função buyTickets
    const transactionDetails = await Promise.all(transactionHashes.map(hash => getTransactionDetails(hash)));

    const buyTicketsTransactions = transactionDetails.filter(details => details !== null);

    return buyTicketsTransactions;
  } catch (error) {
    console.error("Erro ao obter transações internas do usuário:", error);
    return [];
  }
};

// Exemplo de uso da função getBuyTicketsTransactions
export const fetchBuyTicketsTransactions = async (userAddress) => {
  try {
    const transactions = await getBuyTicketsTransactions(userAddress);
    return transactions;
  } catch (error) {
    console.error("Erro ao buscar transações de compra de bilhetes do usuário:", error);
  }
};

export const getPastLotteries = async () => {
  try {
    const data = await contractMDexTicket.methods.getPastLotteries().call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Erro ao obter a loteria atual:", error);
  }
};


export const getCurrentLottery = async () => {
  try {
    const currentLottery = await contractMDexTicket.methods.getCurrentLottery().call();
    // console.log(currentLottery);
    return currentLottery;
  } catch (error) {
    console.error("Erro ao obter a loteria atual:", error);
  }
};

export const getPurchasedTicketIds = async (lottoId) => {
  try {
    const purchased = await contractMDexTicket.methods.getPurchasedTicketIds(lottoId).call();
    //console.log('purchase', purchased);
    return purchased;
  } catch (error) {
    console.error("Erro ao obter a total tickets do usuario:", error);
  }
};

export const getPurchasedTicketDetails = async (lottoId) => {
  try {
    // Obtém os IDs dos tickets comprados
    const purchasedTicketIds = await getPurchasedTicketIds(lottoId);

    const allTickets = [];
    // Usa Promise.all para lidar com múltiplas promessas simultaneamente
    await Promise.all(

      purchasedTicketIds.map(async (ticketId) => {
        
        // Obtém o endereço do dono do ticket
        const userAddress = await contractMDexTicket.methods.ticketOwner(lottoId, ticketId).call();
        //console.log('owner address', userAddress);

        // Obtém os dados do usuário
        const userData = await getUserData(userAddress);
        
        // Obtém a lista completa de tickets do usuário no sorteio
        const userTickets = await getUserTotalTickets(userAddress, lottoId);

        userTickets.map(async (userT) => {
          if (userT.ticketNumber == ticketId){
            allTickets.push({
              ticketId: ticketId,
              userAddress: userAddress,
              user: userData,
              ticket: userT
            });
          }
        });
        
        // Retorna um objeto contendo todos os dados necessários
        
      })
    );

    //console.log(allTickets);
    return allTickets;
  } catch (error) {
    console.error("Erro ao obter os detalhes dos tickets comprados:", error);
  }
};

export const getUserTotalTickets = async (userAddress, lottoId) => {
  try {
    const totalTickets = await contractMDexTicket.methods.getUserTickets(userAddress, lottoId).call();
    //console.log(totalTickets);
    return totalTickets;
  } catch (error) {
    console.error("Error when obtaining the user's total tickets", error);
  }
};

export const getAllUserTickets = async (userAddress) => {
  try {
    const usersTicketsAll = await contractMDexTicket.methods.getAllUserTickets(userAddress).call();
    //console.log(usersTicketsAll);
    return usersTicketsAll;
  } catch (error) {
    console.error("Error getting user all tickets", error);
  }
};

export const getUserTickets = async (userAddress, lottoId) => {
  try {
    const userData = await contractMDex.methods.account(userAddress, lottoId).call();
    //console.log(userData);
    return userData;
  } catch (error) {
    console.error("Error getting user data", error);
  }
};

export const getUserData = async (userAddress) => {
  try {
    const userData = await contractMDex.methods.account(userAddress).call();
    //console.log('userData', userData);
    return userData;
  } catch (error) {
    console.error("Error getting user data", error);
  }
};

export const getUserDataCached = async (userAddress) => {
  try {
    // Verifica se há dados em cache
    const cachedData = Cookies.get(userAddress);
    
    if (cachedData) {
      return JSON.parse(cachedData);
    } else {
      const userData = await contractMDex.methods.account(userAddress).call();
      Cookies.set(userAddress, JSON.stringify({id: userData.id, address: userData.address}), { expires: 7 }); // Define um prazo de expiração, por exemplo, 7 dias
      return userData;
    }
  } catch (error) {
    console.error("Error getting user data", error);
  }
};

export const getUserByIDCache = async (id) => {
  try {
    const cachedData = Cookies.get(id);
    
    if (cachedData) {
      return cachedData;
    } else {
      const user = await contractMDex.methods.accountIds(id).call();
      return user;
    }
    
  } catch (error) {
    console.error("Error getting user data", error);
  }
};

export const getUserByID = async (id) => {
  try {
    const user = await contractMDex.methods.accountIds(id).call();
    return user;
  } catch (error) {
    console.error("Error getting user data", error);
  }
};

export const getTicketPrice = async () => {
  try {
    const ticketPrice = await contractMDexTicket.methods.ticketPrice().call();
    return ticketPrice;
  } catch (error) {
    console.error("Ticket price error:", error);
  }
};

export const getMaxTicketsPerPurchase = async () => {
  try {
    const ticketPrice = await contractMDexTicket.methods.maxTicketsPerPurchase().call();
    return ticketPrice;
  } catch (error) {
    console.error("MaxTickets Error:", error);
  }
};

export const getDoubleBackAlocation = async () => {
  try {
    const data = await contractMDexTicket.methods.alocDB().call();
    return data;
  } catch (error) {
    console.error("Erro ao obter o numero de alocacao para DoubleBack:", error);
  }
};

// Função para comprar bilhetes
export const buyTickets = async (ticketIds, sponsorId, value, account) => {

  try {
    // Chama a função buyTickets do contrato
    const result = await contractMDexTicket.methods.buyTickets(ticketIds, sponsorId).send({
      from: account, // Endereço da carteira
      value: value, // Valor em ether
    });

    // Retorna o resultado da transação
    return result;
  } catch (error) {
    console.error("Erro ao comprar bilhetes:", error);
    throw error; // Rejeita a promessa com o erro
  }
};

// Função para ouvir a resposta da carteira
export const listenForTransaction = (transactionHash, callback) => {
  const eventEmitter = window.ethereum.subscribe('pendingTransactions', (error, txHash) => {
    if (error) {
      console.error('Erro ao ouvir transações pendentes:', error);
    }
    if (txHash === transactionHash) {
      eventEmitter.unsubscribe(); // Cancela a inscrição após encontrar a transação
      callback(); // Chama a função de retorno de chamada quando a transação for confirmada
    }
  });
};
export const listenToSpecificContractEvents = async (contract, eventName, eventHandler) => {
  if (!contract) {
    console.error("Contrato não inicializado corretamente.");
    return;
  }

  const allEvents = [];

  try {
    const pastEvents = await contract.getPastEvents(eventName, {
      fromBlock: 0,
      toBlock: 'latest'
    });
    pastEvents.forEach((pastEvent) => {
      eventHandler(pastEvent);
      allEvents.push(pastEvent);
    });
  } catch (error) {
    console.error(`Erro ao obter eventos passados ${eventName}:`, error);
  }

  contract.events[eventName]()
    .on('data', (futureEvent) => {
      eventHandler(futureEvent);
      allEvents.push(futureEvent);
    })
    .on('error', (error) => console.error(`Erro ao ouvir ${eventName}:`, error));

  return allEvents;
};

export const getContractEvents = async (contracts, eventHandlers) => {
  const allEvents = [];

  for (const [contractName, contract] of Object.entries(contracts)) {
    if (!contract) {
      console.error(`Contrato ${contractName} não inicializado corretamente.`);
      continue;
    }

    try {
      const pastEvents = await contract.getPastEvents('allEvents', {
        fromBlock: 0,
        toBlock: 'latest'
      });
      pastEvents.forEach((pastEvent) => {
        const eventName = pastEvent.event;
        if (eventHandlers[eventName]) {
          eventHandlers[eventName](pastEvent);
        }
        allEvents.push(pastEvent);
      });
    } catch (error) {
      console.error(`Erro ao obter todos os eventos passados do contrato ${contractName}:`, error);
    }
      
  }

  return allEvents;
};

export const getMdexTicketEvents = async () => {
  try {
    const pastEvents = await contractMDexTicket.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest'
    });
    
    //console.log('Ticket Events', pastEvents);
    
    return pastEvents;

  } catch (error) {
    console.error(`Erro ao obter todos os eventos passados do contrato ${contractName}:`, error);
  }

};

export const getMdexEvents = async () => {
  try {
    const pastEvents = await contractMDex.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest'
    });

    // Filtrar eventos relevantes
    const relevantEvents = pastEvents.filter(item =>
      item.event.includes('UserAddedToMatrix') ||
      item.event.includes('NewUser') ||
      item.event.includes('MatrixCommission') ||
      item.event.includes('DirectReferralCommission') ||
      item.event.includes('MatrixPaymentFailed') ||
      item.event.includes('CommissionPaymentFailed')
    );

    // Ordenar eventos por timestamp (data) em ordem decrescente
    const sortedEvents = relevantEvents.sort((a, b) => b.returnValues.timestamp - a.returnValues.timestamp);

    // Limitar a 20 eventos
    return sortedEvents.slice(0, 100);

  } catch (error) {
    console.error(`Erro ao obter todos os eventos passados do contrato ${contractName}:`, error);
  }
};


export const getDoubleBackEvents = async () => {
  try {
    const pastEvents = await contractDoubleBack.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest'
    });
    return pastEvents;

  } catch (error) {
    console.error(`Erro ao obter todos os eventos passados do contrato ${contractName}:`, error);
  }

};


// Função para obter transações internas do contrato
export const getMdexTicketLogs = async (fromBlock = 0, toBlock = 'latest') => {
  try {
    const logs = await web3.eth.getPastLogs({
      fromBlock,
      toBlock,
      address: MDexTicket_ADDRESS
    });

    //console.log('logs', logs);

    const internalTransactions = logs.map(log => {
      return {
        transactionHash: log.transactionHash,
        blockNumber: log.blockNumber,
        from: log.address,
        data: log.data
      };
    });

    //console.log('internalTransactions', internalTransactions);
    return internalTransactions;
  } catch (error) {
    console.error("Erro ao obter transações internas:", error);
  }
};


export const getEventsMDex = async() => {
  const currentBlock = await web3.eth.getBlockNumber();

  return  await contractMDex.getPastEvents('AllEvents', {
    fromBlock: currentBlock - 1000, // Buscar os eventos dos últimos 1000 blocos
    toBlock: 'latest'
  });
};

export const getEventsMDexTicket = async () => {
  const currentBlock = await web3.eth.getBlockNumber();
  
  // Busque os últimos eventos
  return  await contractMDexTicket.getPastEvents('AllEvents', {
    fromBlock: currentBlock - 1000, // Buscar os eventos dos últimos 1000 blocos
    toBlock: 'latest'
  });

};

export const getEventsDoubleBack = async () => {
  const currentBlock = await web3.eth.getBlockNumber();

  return  await contractDoubleBack.getPastEvents('AllEvents', {
    fromBlock: currentBlock - 1000, // Buscar os eventos dos últimos 1000 blocos
    toBlock: 'latest'
  });
};

export const getDoubleBackQueueTotal = async () => {
  try {
    const data = await contractDoubleBack.methods.getTotalQueue().call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting user data", error);
  }
};


export const getDoubleBackQueue = async (start, end) => {
  try {
    const data = await contractDoubleBack.methods.getDoubleBackQueue(start, end).call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting user data", error);
    return [];
  }
};

export const getDoubleBackBalance = async () => {
  try {
    const data = await contractDoubleBack.methods.getContractBalance().call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting doubleback balance", error);
  }
};

export const getDoubleBackUserTotals = async (userAddress) => {
  try {
    const data = await contractDoubleBack.methods.usertotal(userAddress).call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting doubleback balance", error);
  }
};


export const checkUserPositions = async (userAddress) => {
  try {
    const data = await contractDoubleBack.methods.checkUserPositions(userAddress).call();
    //console.log('user positions',[data]);
    return data;
  } catch (error) {
    console.error("Error getting doubleback user positions", error);
  }
};


export const getDoubleBackQueueDetail = async (place) => {
  try {
    const data = await contractDoubleBack.methods.doubleBackQueue(place).call();
    //console.log('Place detail',[data]);
    return data;
  } catch (error) {
    console.error("Error getting doubleback user positions", error);
  }
};


export const getTotalDirectReferrals = async (userAddress) => {
  try {
    const data = await contractMDex.methods.getTotalDirectReferrals(userAddress).call();
    //console.log('directs',data);
    return data;
  } catch (error) {
    console.error("Error getting doubleback balance", error);
  }
};

export const checkDirectsInQueue = async (userAddress) => {
  try {
    const data = await contractMDex.methods.checkDirectsInQueue(userAddress).call();
    //console.log('directs',data);
    return data;
  } catch (error) {
    console.error("Error getting doubleback balance", error);
  }
};


export const getDirectReferrals = async (userAddress) => {
  try {
    const data = await contractMDex.methods.getDirectReferrals(userAddress).call();
    //console.log('directs',data);
    return data;
  } catch (error) {
    console.error("Error getting doubleback balance", error);
  }
};



export const getTotalMatrix = async (userAddress, levels) => {
  try {
    const data = await contractMDex.methods.getTotalMatrix(userAddress, levels).call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting user total matrix", error);
  }
};

export const getUserTotalDownlinesLevel = async (userAddress, level) => {
  try {
    const data = await contractMDex.methods.getUserTotalDownlinesLevel(userAddress, level).call();
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting user total downlines by level", error);
  }
};


