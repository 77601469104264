import React, {useState, useEffect} from 'react';

import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import { IoCashOutline} from 'react-icons/io5';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { Puff } from 'react-loading-icons';
import Web3 from 'web3';
import { formatCurrency } from 'utils/format';
import { maticToCurrency } from 'utils/format';
import { fromWei } from 'utils/format';
import { formatWei } from 'utils/format';

const ReturnRate = ({userData}) => {
	const { info, gradients } = colors;
	const { cardContent } = gradients;
    const [totalProfit, setTotalProfit] = useState(0);

	
   
	return (
		<Card sx={{ height: '340px' }}>
			<VuiBox display='flex' flexDirection='column'>
				<VuiTypography variant='lg' color='white' fontWeight='bold' mb='4px'>
                Account return
				</VuiTypography>
				<VuiTypography variant='button' color='text' fontWeight='regular' mb='20px'>                
                Track total purchases and total earnings this account has made
				</VuiTypography>
				<VuiBox sx={{ alignSelf: 'center', justifySelf: 'center', zIndex: '-1' }}>
					<VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress variant='determinate' value={userData ? 
						
						Number(((
							Number(userData.profitDirect) + Number(userData.profitProduct) + Number(userData.Matrix.profit)
						) / userData.totalBuy) * 100).toFixed(0) <= 100 ?
						Number(((
							Number(userData.profitDirect) + Number(userData.profitProduct) + Number(userData.Matrix.profit)
						) / userData.totalBuy) * 100).toFixed(0)
						: 100
						
						: 0} size={170} color='success' />
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox
								sx={{
									background: info.main,
									transform: 'translateY(-50%)',
									width: '50px',
									height: '50px',
									borderRadius: '50%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}>
								<IoCashOutline size='30px' color='#fff' />
							</VuiBox>
						</VuiBox>
					</VuiBox>
				</VuiBox>
				<VuiBox
					sx={({ breakpoints }) => ({
						width: '100%',
						padding: '15px 20px',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						height: '82px',
						mx: 'auto',
						borderRadius: '20px',
						background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
						transform: 'translateY(-90%)',
						zIndex: '1000'
					})}>
					<VuiTypography color='text' variant='caption'  fontWeight='regular' sx={{lineHeight: '10px'}}>
                    Total applied
                        <VuiTypography color='white'  fontWeight='regular' display='block' sx={{fontSize: '16px'}}>
						{userData ? formatWei(userData.totalBuy, 'ether') : <Puff />}
					    </VuiTypography>
                        <VuiTypography color='text'  fontWeight='regular' sx={{fontSize: '10px'}} display='block'>
						{userData ? (
                            formatCurrency(Web3.utils.fromWei(userData.totalBuy, 'ether') * Cookies.get('maticPrice'))
                        ): <Puff />}
					    </VuiTypography>
					</VuiTypography>
					<VuiBox
						flexDirection='column'
						display='flex'
						justifyContent='center'
						alignItems='center'
						sx={{ minWidth: '80px' }}>
						<VuiTypography color={'success'} variant='h5'>
                            {userData ? Number((
								(Number(userData.profitDirect) + Number(userData.profitProduct) + Number(userData.Matrix.profit)) / userData.totalBuy) * 100).toFixed(0) : <Puff />}%
						</VuiTypography>
						<VuiTypography color='text' variant='caption' fontWeight='regular'>
							Returned
						</VuiTypography>
					</VuiBox>
					<VuiTypography color='text' variant='caption'  fontWeight='regular' sx={{lineHeight: '10px'}}>
						Received
                        <VuiTypography color='white'  fontWeight='regular' display='block' sx={{fontSize: '16px'}}>
						{userData ? (
                            formatWei(Number(userData.profitDirect) + Number(userData.profitProduct) + Number(userData.Matrix.profit))
                        ): <Puff />}
					    </VuiTypography>
                        <VuiTypography color='text'  fontWeight='regular' sx={{fontSize: '10px'}} display='block'>
						{userData ? 
						maticToCurrency(fromWei(
							Number(userData.profitDirect) + Number(userData.profitProduct) + Number(userData.Matrix.profit)
						)) : <Puff />} 
					    </VuiTypography>
					</VuiTypography>
					
				</VuiBox>
			</VuiBox>
		</Card>
	);
};

export default ReturnRate;
