import React, { useEffect, useState, memo, useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoCopy, IoOpenOutline } from "react-icons/io5";
import { formatAddress } from "utils/format";
import VuiBox from "./VuiBox";
import VuiTypography from "./VuiTypography";
import { Link } from "@mui/material";
import { getUserDataCached } from "utils/contracts";
import VuiBadge from "./VuiBadge";
import { v4 as uuidv4 } from 'uuid';

const Copied = () => {
  enqueueSnackbar('Address copied!', {
    variant: 'success'
  });
};

const UserAddressComponent = memo(({ address }) => {
  const [userDetail, setUserDetail] = useState(null);

  
  useEffect(() => {
    async function fetchData(){
      const user = await getUserDataCached(address);
      setUserDetail(user);
    }
    fetchData();
  }, [address]);

  return (
    <VuiBox display="flex" flexDirection="row" key={uuidv4()}>
      <VuiBadge badgeContent={`ID: ${userDetail ? userDetail.id : ''}`} color={'info'} size={'md'} sx={{marginRight: '5px'}} />
      <VuiBox>
        <CopyToClipboard text={address} onCopy={() => Copied()}>
          <VuiTypography color='light' variant="body">
            {formatAddress(address)} <IoCopy size={15} />
          </VuiTypography>
        </CopyToClipboard>
        <Link href={`${process.env.REACT_APP_EXPLORER_URL}/address/${address}`} target='_blank'>
          <VuiTypography color='light' variant='body' sx={{ marginLeft: '5px' }}>
            <IoOpenOutline size={15} />
          </VuiTypography>
        </Link>
      </VuiBox>
    </VuiBox>
  );
});

export default UserAddressComponent;
