/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { Card, Stack, Grid, Input, Switch } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import web3 from '../utils/web3';
import contractMDexTicket, { getCurrentLottery, getTicketPrice} from 'utils/contracts';
import {buyTickets} from 'utils/contractInteract';
import TotalBuyButton from "components/VuiButton/totalBuy";

import ConfettiExplosion from 'react-confetti-explosion';
import {getAccountWallet, checkNetworkWallet} from 'detect';

import Loader from "./Loader";

import { enqueueSnackbar } from 'notistack';
import Cookies from "js-cookie";
import { TicketBadge } from "./VuiBadge/ticketBadge";
import { fromWei } from "utils/format";
import { formatWei } from "utils/format";
import { Puff } from "react-loading-icons";
import { maticToCurrency } from "utils/format";
import { getNativeCoin } from "utils/format";
import { isAddress } from "utils/format";
import VuiSwitch from "./VuiSwitch";
import VuiInput from "./VuiInput";
import { getUserByID } from "utils/contracts";
import { getUserByIDCache } from "utils/contracts";
import { isZeroAddress } from "utils/format";
import { getUserDataCached } from "utils/contracts";
import { getMaxTicketsPerPurchase } from "utils/contracts";

const BuyTicket = () => {
  const [isExploding, setIsExploding] = React.useState(false);
	const [ticketPrice, setTicketPrice] = useState(0);
	const [selectedTickets, setSelectedTickets] = useState([]);
	const [totalPurchase, setTotalPurchase] = useState(0);
	const [availableTickets, setAvailableTickets] = useState([]);
	const [purchasedTickets, setPurchasedTickets] = useState([]);
	const [currentLottery, setCurrentLottery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressValid, setIsValid] = useState(false);
  const [diffAddress, setDiffAddress] = useState(false);
  const [addressBuyer, setAddressBuyer] = useState(null);
  const [diffSponsor, setDiffSponsor] = useState(null);
  const [autoPurchase, setAutoPurchase] = useState(true);
  const [limitPurchase, setLimitPurchase] = useState(0);

  const handleAutoPurchase = async (event) => {
    setAutoPurchase(event.target.checked)
  }

  const handleBuyerAddress = async (event) => {
    const newAddress = event.target.value;
    const charStr = String.fromCharCode(newAddress);

    // Verifica se o caractere é hexadecimal
    if (!charStr.match(/[0-9a-fA-F]/)) {
      event.preventDefault();
    }

    if (isAddress(newAddress)){
      setAddressBuyer(newAddress);
      setIsValid(true);
    }else{
      setAddressBuyer(null);
      setIsValid(false);
      // await enqueueSnackbar('Invalid Address', {
      //   variant: 'error'
      // });
    }
  };

  const handleDiffSponsor = async (event) => {
      setDiffSponsor(event.target.value);
  }

  const handleRadioChange = (event) => {
    //console.log(event.target.checked);
    setDiffAddress(event.target.checked);
    setAddressBuyer(null);
    setIsValid(null);
  };


  const bigExplodeProps = {
    force: 0.6,
    duration: 5000,
    particleCount: 400
  };

  async function fetchData() {
		try {
      setSelectedTickets([]);
      setTotalPurchase(0);
      
			const limit = await getMaxTicketsPerPurchase();
      
      setLimitPurchase(Number(limit));
      
			const ticketPrice = await getTicketPrice();

			setTicketPrice(web3.utils.fromWei(ticketPrice, 'ether'));

			const currentLotteryData = await getCurrentLottery();
			
      setCurrentLottery(currentLotteryData);
      const availableTicketIds = [];

      if (Number(currentLotteryData.ticketCount) > 0){
        const purchasedTicketsData = await contractMDexTicket.methods.getPurchasedTicketIds(currentLotteryData.id).call();

        setPurchasedTickets(purchasedTicketsData);
        for (let i = 1; i <= Number(currentLotteryData.ticketAvailable) + Number(currentLotteryData.ticketCount); i++) {
          if (!purchasedTicketsData.map(Number).includes(i)) {
            availableTicketIds.push(i);
          }
        }
      }else{
        for (let i = 1; i <= Number(currentLotteryData.ticketAvailable) + Number(currentLotteryData.ticketCount); i++) {
          availableTicketIds.push(i);
        }
      }
      
			setAvailableTickets(availableTicketIds);
      
		} catch (error) {
			console.error("Erro ao buscar dados:", error);
		}
		}
  
	useEffect(() => {
		fetchData();
	}, []);

	const handleTicketSelect = async (ticketId) => {
    //console.log(ticketId);
		if (selectedTickets.includes(ticketId)) {
      setSelectedTickets(selectedTickets.filter((id) => id !== ticketId));
      setTotalPurchase(totalPurchase - Number(ticketPrice));  
      
		} else {
      if (selectedTickets.length < limitPurchase){
        setSelectedTickets([...selectedTickets, ticketId]);
        setTotalPurchase(totalPurchase + Number(ticketPrice));
      }else{
        await enqueueSnackbar(`You can choose a maximum of ${limitPurchase} tickets per purchase`, {
          variant: 'error'
        });
      }
      
		}
	};

  const handleBuyTicket = async () => {

    setLoading(true);
    if (selectedTickets.length == 0){
      await enqueueSnackbar('Select your tickets', {
        variant: 'error'
      });
     }else{
      const account = await getAccountWallet();
      try {
        if (account) {
          if (account.balance < totalPurchase){
            await enqueueSnackbar('Insufficient balance', {
              variant: 'error'
            });
          }else{

            let accountBuyer = account.account;
            let valid = true;
            let SponsorId = Cookies.get('refId');

            if (diffAddress){
              accountBuyer = addressBuyer;

              if (!addressValid){
                valid = false;
              }
              if (diffSponsor){

                let checkId = await getUserByIDCache(diffSponsor);

                if (!isZeroAddress(checkId)){
                  SponsorId = diffSponsor;
                }else{
                  checkId = await getUserDataCached(account.account);
                  if (checkId && !isZeroAddress(checkId)){
                    SponsorId = checkId.id;
                  }
                }
              }
              
            }
            
            if (valid){
              const _buy = await buyTickets(selectedTickets, SponsorId, totalPurchase, account.account, accountBuyer, autoPurchase);
              if (_buy){
                setLoading(false);
                setIsExploding(!isExploding);
                fetchData();
              }
            }else{
              await enqueueSnackbar('Invalid address', {
                variant: 'error'
              });
            }
            
    
          }
          
        } else {
          await enqueueSnackbar('Please connect your wallet first.', {
            variant: 'warning'
          });
        }
      } catch (error) {
        console.error("There was an error purchasing the ticket, please try again.", error);
        await enqueueSnackbar('There was an error purchasing the ticket, please try again.', {
          variant: 'error'
        });
      }
     }

     setLoading(false);
    
  };

	return (
		<Grid mt="20px" container spacing={3} >
        <Grid item xs={12} xl={8} >
          <Card >
            <VuiBox display="flex" flexDirection="column" height="100%">
              <VuiBox display="flex" flexDirection="column" mb="4px">
                <VuiTypography color="white" variant="lg" fontWeight="bold" mb="6px">
                  Pick up your tickets
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="regular">
                  Each ticket has a unique ID to compete in the draw.
                </VuiTypography>
              </VuiBox>
              <Grid container spacing={3} ml="0px" mt="10px" style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                {availableTickets.map((ticketId) => (
                  <a
                    key={ticketId}
                    href={"#" + ticketId}
                    className={ selectedTickets.includes(ticketId) ? "buttonTicket buttonTicketSelected" : "buttonTicket"}
                    onClick={() => handleTicketSelect(ticketId)}
                  >
                    {ticketId}
                  </a>
                ))}
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
        <VuiBox display="flex" flexDirection="column" >
              <VuiBox display="flex" flexDirection="column" mb="5px">
                <VuiTypography color="white" variant="lg" fontWeight="bold">
                  Selected tickets
                </VuiTypography>
              </VuiBox>
              <Grid container spacing={3} ml="0px" mt='0px'>
                {selectedTickets.length > 0 ? (
                  selectedTickets.map((ticketId) => (
                    <a
                    key={ticketId}
                    href={"#" + ticketId}
                    className={ selectedTickets.includes(ticketId) ? "buttonTicket buttonTicketSelected" : "buttonTicket"}
                    onClick={() => handleTicketSelect(ticketId)}
                  >
                    {ticketId}
                  </a>
                  ))
                ) : (
                  <VuiTypography color="text" variant="button" fontWeight="regular" mb='0px' mt="10px">
                    You have not selected any tickets
                  </VuiTypography>
                )}
              </Grid>
              
              <Grid container spacing={0} rowSpacing={0.5} ml="0px" mt={0.1} mb={2} >

                <Grid item md={6} sx={{
                  display: { xs: "none", lg: "block" }
                }} rowSpacing={0.5}>
                  <VuiTypography color="text" variant="button" fontWeight="regular" mb='0px'>
                    Price
                  </VuiTypography>
                    <VuiTypography color='white' variant='h6' mb='0px' mt='0px'>
                      {ticketPrice} {web3.coin}
                      <VuiTypography color='white' variant='caption' mb='0px' mt='0px' display='block'>
                      {currentLottery ? maticToCurrency(ticketPrice) : '--'}
                      </VuiTypography>
                  </VuiTypography>
                </Grid>

                <Grid item md={6} sx={{
                    display: { xs: "none", lg: "block" }
                  }}>
                  <VuiTypography color="text" variant="button" fontWeight="regular" mb='0px'>
                    Prize draw
                  </VuiTypography>
                    <VuiTypography color='success' variant='h6' mb='0px' mt='0px'>
                    {currentLottery ? formatWei(currentLottery.prize) : <Puff />}
                      <VuiTypography color='white' variant='caption' mb='0px' mt='0px' display='block'>
                      {currentLottery ? maticToCurrency(fromWei(currentLottery.prize)) : '--'}
                      </VuiTypography>
                  </VuiTypography>
                </Grid>

                <Grid item sm={12} md={6} rowSpacing={0} sx={{
                    display: { xs: "none", lg: "block" }
                  }}>
                  <VuiTypography color="text" variant="button" fontWeight="regular" mb='0px'>
                    Guaranteed return
                  </VuiTypography>
                    <VuiTypography color='success' variant='h6' mb='0px' mt='0px'>
                    {totalPurchase * 2} {getNativeCoin()} (2x)
                    <VuiTypography color='success' variant='caption' mb='0px' mt='0px' display='block'>
                      {totalPurchase ? maticToCurrency(totalPurchase * 2) : '--'}
                      </VuiTypography>
                  </VuiTypography>
                </Grid>

              </Grid>
              
              {isExploding && (
                <ConfettiExplosion {...bigExplodeProps} />
              )}

              {totalPurchase > 0 ?
              <VuiBox flexDirection='column'>
                <VuiBox flexDirection='row' mb={'3px'}>
                  <VuiTypography color={'white'} variant={'body2'}>
                    
                    <Switch color="warning" onChange={handleRadioChange} style={{marginRight: '3px'}}/>  
                   {' Are you reselling?'}
                  </VuiTypography>
                  
                </VuiBox>

                {diffAddress ? 
                  <VuiBox mb={'5px'}>
                    <VuiTypography color={'white'} variant={'body2'}>Add buyer's address below</VuiTypography>
                    <VuiInput placeholder="Enter the address" onChange={handleBuyerAddress}  
                    success={addressValid === true}
                    error={addressValid === false}
                    />

                  <VuiTypography color={'white'} variant={'body2'}>Sponsor ID</VuiTypography>
                    <VuiInput type='number' placeholder="Enter Sponsor ID" onChange={handleDiffSponsor}
                    />
                  </VuiBox>
                :<></>}

                {/* <VuiBox flexDirection='row' mb={'10px'}>
                  <VuiTypography color={'white'} variant={'body2'}>
                  <Switch onChange={handleAutoPurchase} defaultChecked={autoPurchase} style={{marginRight: '5px'}} color="warning"/>  
                  {' Enable automatic repurchase?'}
                  </VuiTypography>
                  
                </VuiBox> */}

                
                <button
                  variant="contained"
                  color="primary"
                  onClick={handleBuyTicket}
                  disabled={loading}
                  className="buttonBuy"
                  >
                  {loading ? <Loader /> : 'Pay Now '+ totalPurchase + ' ' + getNativeCoin()}
                </button>

              </VuiBox>
                
              :<></>
            }
            </VuiBox>
        </Grid>
    </Grid>
	);
};

export default BuyTicket;
