import React from 'react';
import { Input, TextField } from '@mui/material';
import VuiInput from './VuiInput';

const SearchBar = ({ value, onChange }) => {
  return (
    <input
      placeholder="Search..."
      value={value}
      onChange={onChange}
      className={'searchInput'}
    />
  );
};

export default SearchBar;
