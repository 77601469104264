import React, {useState, useEffect} from 'react';
import { Card, Stack } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import { getTotalDirectReferrals } from 'utils/contracts';

import { Puff } from 'react-loading-icons';
import { checkUserPositions } from 'utils/contracts';
import { checkDirectsInQueue } from 'utils/contracts';

function ReferralTracking({address, userData}) {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	const [totalDirects, setTotalDirects] = useState(null);
	const [totalMatrix, setTotalMatrix] = useState(0);

    useEffect(() => {
        
        async function fetchData() {
            
            if (address){
				//console.log(address);
                let totalsData = await checkDirectsInQueue(address);
                setTotalDirects(totalsData);

				const userPositionsData = await checkUserPositions(address);
				//gconsole.log('posicions DB', userPositionsData.length);
                setTotalMatrix(userPositionsData ? userPositionsData.length : 0);
            }

        }
    
        fetchData();
      }, []);

	return (
		<Card
			sx={{
				height: '100%',
				background: linearGradient(gradients.cardDark.main, gradients.cardDark.state, gradients.cardDark.deg)
			}}>
			<VuiBox sx={{ width: '100%' }}>
				<VuiBox
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='40px'>
					<VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
						Criptomarketing Matrix Status
					</VuiTypography>
					
				</VuiBox>
				<VuiBox
					display='flex'
					sx={({ breakpoints }) => ({
						[breakpoints.up('xs')]: {
							flexDirection: 'column',
							gap: '16px',
							justifyContent: 'center',
							alignItems: 'center'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}
					})}>
					<Stack
						direction='column'
						spacing='20px'
						width='500px'
						maxWidth='50%'
						sx={({ breakpoints }) => ({
							mr: 'auto',
							[breakpoints.only('md')]: {
								mr: '75px'
							},
							[breakpoints.only('xl')]: {
								width: '500px',
								maxWidth: '40%'
							}
						})}>
						<VuiBox
							display='flex'
							width='220px'
							p='20px 22px'
							flexDirection='column'
							sx={({ breakpoints }) => ({
								background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
								borderRadius: '20px',
								[breakpoints.up('xl')]: {
									maxWidth: '110px !important'
								},
								[breakpoints.up('xxl')]: {
									minWidth: '180px',
									maxWidth: '100% !important'
								}
							})}>
							<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								Invited
							</VuiTypography>
							<VuiTypography color='white' variant='lg' fontWeight='bold'>
								{totalDirects ? totalDirects : <Puff />} / 3
							</VuiTypography>
						</VuiBox>
						<VuiBox
							display='flex'
							width='220px'
							p='20px 22px'
							flexDirection='column'
							sx={({ breakpoints }) => ({
								background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
								borderRadius: '20px',
								[breakpoints.up('xl')]: {
									maxWidth: '110px !important'
								},
								[breakpoints.up('xxl')]: {
									minWidth: '180px',
									maxWidth: '100% !important'
								}
							})}>
							<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								This account
							</VuiTypography>
							<VuiTypography color='white' variant='lg' fontWeight='bold'>
								{totalMatrix >= 0 ? totalMatrix : <Puff />} / 1
							</VuiTypography>
						</VuiBox>
					</Stack>
					<VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress
							variant='determinate'
							value={100}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color={userData ? userData.Matrix.isActive ?  "success" : "error" : "info"}
						/>
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								<VuiTypography color={userData ? userData.Matrix.isActive ?  "success" : "error" : 'info'} variant='button'>
									{userData ? userData.Matrix.isActive ?  "Active for earnings" : "Inactive for Earnings" : <></> }
								</VuiTypography>
							</VuiBox>
						</VuiBox>
					</VuiBox>
				</VuiBox>
			</VuiBox>
		</Card>
	);
}

export default ReferralTracking;
