import { checkAccountWallet } from 'detect';
import Web3 from 'web3';

let web3;

async function check(){
  return await checkAccountWallet();
}

if (window.ethereum) {
  // MetaMask is installed
  const accountData = check();
  if (accountData) {
    web3 = new Web3(window.ethereum);
  }else{
    web3 = new Web3(process.env.REACT_APP_INFURA_URL);
  }
  console.log('provider', 'window.ethereum');

} else if (window.web3) {
  // Legacy dapp browsers...
  web3 = new Web3(window.web3.currentProvider);
  console.log('provider', window.web3.currentProvider);
} else {
  // Fallback to Infura if no web3 instance is provided
  console.log('provider', process.env.REACT_APP_INFURA_URL);
  web3 = new Web3(process.env.REACT_APP_INFURA_URL);
}

web3.coin = process.env.REACT_APP_NETWORK_CURRENCY;
web3.explorer = process.env.REACT_APP_EXPLORER_URL;

export default web3;
