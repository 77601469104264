import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { enqueueSnackbar } from "notistack";
import { Card, Grid } from "@mui/material";

const SearchBarAccount = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const handleSearch = () => {
    const trimmedSearchTerm = searchTerm.trim();

    if (!trimmedSearchTerm) {
      enqueueSnackbar("Please enter a valid search term.", { variant: "error" });
      return;
    }

    // Check if searchTerm is an Ethereum address
    if (isEthereumAddress(trimmedSearchTerm)) {
      //history.push(`/account?a=${trimmedSearchTerm}`);
      window.location.href = `/account?a=${trimmedSearchTerm}`;
    } else {
      window.location.href = `/account?uId=${trimmedSearchTerm}`;
      //history.push(`/account?uId=${trimmedSearchTerm}`);
    }
  };

  const isEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  return (

    <Card>
        <VuiBox flexDirection='column'>
        <VuiTypography color='white' variant='h5'> Want to view an account? </VuiTypography>
        <VuiTypography color='white' variant='caption'> You can search for an account by an ID or Address to view earnings and full account details.</VuiTypography>
            <Grid container>
              <Grid item xs={10} alignContent={'center'} spacing={1}>
                <VuiInput
                  type="text"
                  variant="outlined"
                  placeholder="Enter with User ID or Address..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  icon={{
                      component: "search",
                      direction: "left",
                  }}
              />
              </Grid>
              <Grid item xs={'auto'} alignContent={'center'}>
                <VuiButton
                  style={{
                  marginLeft: 5,
                  }}
                  color="primary"
                  onClick={handleSearch}
                >
                  Go!
                </VuiButton>
              </Grid>
            </Grid>
       </VuiBox>
    </Card>
    
  );
};

export default SearchBarAccount;
