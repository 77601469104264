import React, { useEffect, useState } from "react";

import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/background_peoples.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import VuiButton from "./VuiButton";
import { maticToCurrency } from "utils/format";
import { fromWei } from "utils/format";
import { formatWei } from "utils/format";

const UserTotalDirects = ({id, total}) => {
  
  return (
    <Card sx={() => ({
      height: "240px",
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%"
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Earnings from direct referrals
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mt="0px" lineHeight='20px'>
          {maticToCurrency(fromWei(total))}
          </VuiTypography>
          <VuiTypography color="white" variant="botton" fontWeight="bold" mt="10px" lineHeight='15px'>
          {formatWei(total)}
          </VuiTypography>

        </VuiBox>  
          <NavLink to={`/direct-users?userId=${id}`}><VuiButton color='primary' variant='contained' size={'medium'}>Open</VuiButton></NavLink>
      </VuiBox>
    </Card>
  );
};

export default UserTotalDirects;
