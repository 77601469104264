import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';

import { getCurrentLottery, getTicketPrice, getPurchasedTicketDetails } from 'utils/contracts';
import Table from "examples/Tables/Table";
import VuiBadge from "./VuiBadge";
import { formatDateTime } from "utils/format";
import { formatAddress } from "utils/format";
import SearchBar from './SearchBar';
import UserAddressComponent from "./UserAddressComponent";
import { AddressCopy } from "./AddressCopy";

const TicketsHistory = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [purchasedTicket, setPurchased] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    async function fetchData() {
        const currentLotteryData = await getCurrentLottery();
        const getPurchasedTicketDetailsData = await getPurchasedTicketDetails(currentLotteryData.id);
        setPurchased(getPurchasedTicketDetailsData);
    }

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTickets = purchasedTicket ? purchasedTicket.filter(item =>
    item.userAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.ticketId.toString().includes(searchTerm) ||
    item.user.id.toString().includes(searchTerm) ||
    formatDateTime(item.ticket.timestamp).toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];


  const tableRow = () => {
    return filteredTickets.map((item) => ({
      Ticket: (
        <Grid container spacing={3} ml="0px" mt="10px" style={{ height: '100%', overflow: 'auto' }}>
                  <a
                    key={item.ticketId}
                    href={"#" + item.ticketId}
                    className={"buttonTicket buttonTicketSelected"}
                  >
                    {item.ticketId}
                  </a>
              </Grid>
      ),
      UserID: (
        <VuiBadge badgeContent={`ID: ${item.user.id}`} color={'info'} size={'md'} sx={{marginRight: '5px'}} />
      ),
      User: <AddressCopy address={item.userAddress} />,
      Date: formatDateTime(item.ticket.timestamp),
    }));
  };

  return (
    <Card >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Tickets sold
          </VuiTypography>
        </VuiBox>
        <VuiBox>
        {filteredTickets.length > 0 ? <SearchBar value={searchTerm} onChange={handleSearch} /> : <></>}
        </VuiBox>
      </VuiBox>
     
      <Grid container style={{ maxHeight: '75vh', overflowY: 'auto' }}>
        {filteredTickets.length > 0 ?<Table
          columns={[
            { name: "Ticket", align: "left" },
            { name: "UserID", align: "left" },
            { name: "User", align: "left" },
            { name: "Date", align: "left" },
          ]}
          rows={tableRow()}
        />
        :
        <VuiTypography color='white' variant='button'>Be the first to choose tickets for the open draw.</VuiTypography>
    }
      </Grid>
    </Card>
  );
};

export default TicketsHistory;
