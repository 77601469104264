import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import Table from "examples/Tables/Table";

import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';

import { getUserByID } from "utils/contracts";
import { getDirectReferrals } from "utils/contracts";
import { formatAddress } from "utils/format";
import { getUserData } from "utils/contracts";
import { AddressCopy } from "./AddressCopy";
import { formatDateTime } from "utils/format";
import SearchBar from "./SearchBar";
import VuiBadge from "./VuiBadge";
import { maticToCurrency } from "utils/format";
import { fromWei } from "utils/format";
import { formatWei } from "utils/format";

const DirectReferal = ({address}) => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {

    async function fetchData() {
        if (address){
            let totalsData = await getDirectReferrals(address);
            setData(totalsData);
        }

    }

    fetchData();
  }, [address]);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTickets = data ? data.filter(item =>
    item.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.id.toString().includes(searchTerm)
  ) : [];

  const tableRow = () => {
    console.log(filteredTickets);
    return filteredTickets.map((item) => ({
      User: <VuiBox display="flex" flexDirection="row" key={item.id}>
          <VuiBadge badgeContent={`ID: ${item.id}`} color={'info'} size={'md'} sx={{marginRight: '5px'}} />
          <AddressCopy address={item.user} />
      </VuiBox>,
      Matrix: <VuiTypography color={item.Matrix.isActive ? 'success' : 'error'} variant='buttom'>{item.Matrix.isActive ? 'Active' : 'Inactive'}</VuiTypography>,
      Repurchase: <VuiTypography color={item.autoPurchase ? 'success' : 'error'} variant='buttom'>{item.autoPurchase ? 'Enabled' : 'Disabled'}</VuiTypography>,
      'Return Queue': <VuiTypography color={item.queueReturn ? 'success' : 'error'} variant='buttom'>{item.queueReturn ? 'Yes' : 'No'}</VuiTypography>,
      'Total earnings': <VuiTypography color={'white'} variant='buttom'> {formatWei(Number(item.profitDirect) + Number(item.profitProduct) + Number(item.Matrix.profit))}</VuiTypography>,
      Date: formatDateTime(item.timestamp),
    }));
  };

  return (
    <Card >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Direct Referrals
          </VuiTypography>
        </VuiBox>
        <VuiBox>
        {filteredTickets.length > 0 ? <SearchBar value={searchTerm} onChange={handleSearch} /> : <></>}
        </VuiBox>
      </VuiBox>
     
      <Grid container>
        {filteredTickets.length > 0 ?<Table
          columns={[
            { name: "User", align: "left" },
            { name: "Matrix", align: "left" },
            { name: "Repurchase", align: "left" },
            { name: "Return Queue", align: "left" },
            { name: "Total earnings", align: "left"},
            { name: "Date", align: "left" },
          ]}
          rows={tableRow()}
        />
        :
        <VuiTypography color='white' variant='button'>You do not have any direct referrals</VuiTypography>
    }
      </Grid>
    </Card>
  );
};

export default DirectReferal;