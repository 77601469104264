import React, { useEffect, useState } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

import colors from 'assets/theme/base/colors';
import { formatAddress } from "utils/format";
import { getUserData } from "utils/contracts";
import { MatrixSlot } from "./VuiBadge/MatrixSlot";
import { getUserDataCached } from "utils/contracts";

const UserMatrixNavigation = ({ downlines = [], user }) => {
    
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [matrix, setMatrix] = useState([]);
  const [upline, setUpline] = useState(null);
  const [account, setAccount] = useState(null);

  async function getDownlines(sponsor) {
    let userData = await getUserData(sponsor);
    return matrixStruct(userData);
  }

  const matrixStruct = (userData) => {
    return {
      id: userData.id,
      user: userData.user,
      downlines: userData.Matrix.downlines,
      isActive: userData.Matrix.isActive,
      upline: userData.Matrix.upline
    };
  }

  async function matrixData(InitAddress = null) {
    const fetchMatrix = async (addresses, level = 0) => {
      if (level > 2) return [];

      const matrixLevel = await Promise.all(
        addresses.map(async address => {
          const userDownlines = await getDownlines(address);
          return {
            id: userDownlines.id,
            user: userDownlines.user,
            isActive: userDownlines.isActive,
            upline: userDownlines.upline,
            downlines: await fetchMatrix(userDownlines.downlines, level + 1)
          };
        })
      );
      return matrixLevel;
    };

    const initialDownlines = InitAddress ? (await getDownlines(user.user)).downlines : downlines;
    const completeMatrix = await fetchMatrix(initialDownlines);
    setMatrix(completeMatrix);

    let uplineData = await getUserDataCached(user.Matrix.upline);
    setUpline(uplineData);

  }

  useEffect(() => {
    setMatrix([]);
    setAccount(matrixStruct(user))
    matrixData();
  }, [user]);

  const handleUserClick = (address) => {
    setMatrix([]);
    matrixData(address);
  };

  return (
    <Card >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Spilling Matrix
          </VuiTypography>
        </VuiBox>
        <VuiBox>
        
        </VuiBox>
      </VuiBox>
     
      
    <Grid container spacing={1}>
      {/* {upline ? 
        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
          <VuiTypography variant='button' color='white'>User Upline</VuiTypography>
          <MatrixSlot userData={upline} />
        </Grid>
      :<></>
      } */}
      

      <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
        <MatrixSlot userData={account} />
      </Grid>

      {matrix.map((item, index) => (
        <Grid item xs={4} sx={{ textAlign: 'center' }} key={index}>
          <MatrixSlot userData={item} />

            <Grid container >
              {item.downlines.map((downline, index2) => (
                <Grid item xs={4} key={index2}>
                 <MatrixSlot userData={downline} />
                    <Grid sx={{
                        display: { xs: "none", lg: "inline" }
                      }}>
                        <Grid container>
                        {downline.downlines.map((downline2, index3) => (
                            <Grid item xs={4} sm={4} key={index3}>
                              <MatrixSlot userData={downline2} mini={true} />
                            </Grid>
                        ))}
                      </Grid>
                    </Grid>
                </Grid>
              ))}
            </Grid>
        </Grid>
      ))}
    </Grid>
    </Card>
  );
}

export default UserMatrixNavigation;
