import { Link } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { IoPerson, IoPersonCircleOutline } from "react-icons/io5";
import Puff from "react-loading-icons/dist/esm/components/puff";
import { NavLink, useLocation } from "react-router-dom";
import { formatAddress } from "utils/format";
export const MatrixSlot = ({userData, mini = false}) => {

    const setColor = () => {
        if (userData.id == 0){
            return 'dark';
        }else if(userData.isActive){
            return 'success';
        }else{
            return 'error';
        }
    }

    const openUser = () => {
        //return window.location.href;
    }

  
    return  <Link href={userData ? userData.id > 0 ? '/account?uId=' + userData.id : '#' : '#'}>
        <VuiBox
            color={"white"}
            bgColor={userData ? setColor() : "dark"}
            width={'100%'}
            minHeight="60px"
            borderRadius={'10px'}
            textAlign='center'
            fontSize={'14px'}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mb={1}
        >
            {userData ? 
                userData.id > 0 ? (
                    <VuiBox>
                        <VuiTypography color='white' variant='h6'> {userData.id}</VuiTypography>
                        {!mini ? <VuiTypography color='white' variant='caption'
                        sx={{
                            display: { xs: "none", lg: "block" }
                          }}
                          >
                            {formatAddress(userData.user)}</VuiTypography> 
                        : <> </>}
                    </VuiBox>
            ) : (
                !mini ? 
                <VuiTypography color='white' variant='botton' sx={{
                    display: { xs: "none", lg: "block" }
                  }}
                  >Empty
                  </VuiTypography>
                : <></>
            )
            : (
                <Puff />
            )}
            </VuiBox>
    </Link>
}