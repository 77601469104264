import Web3 from 'web3';
const web3 = new Web3(window.ethereum);

import MDexTicket from '../abis/MDexTicket.json';
import MDex from '../abis/MDex.json';
import DoubleBack from '../abis/DoubleBack.json';

import {enqueueSnackbar } from 'notistack';

import Addresses from '../abis/addresses.json';


const chainId = process.env.REACT_APP_CHAIN_ID;

const MDexTicket_ABI = MDexTicket.abi;
const MDexTicket_ADDRESS = Addresses[chainId]?.MDexTicket || null;

const DOUBLEBACK_ABI = DoubleBack.abi;
const DOUBLEBACK_ADDRESS = Addresses[chainId]?.DoubleBack || null;

const MDex_ABI = MDex.abi;
const MDex_ADDRESS = Addresses[chainId]?.MDex || null;

if (!MDexTicket_ADDRESS || !DOUBLEBACK_ADDRESS || !MDex_ADDRESS) {
  throw new Error(`No contract addresses found for chain ID: ${chainId}`);
}

const contractMDexTicket = new web3.eth.Contract(MDexTicket_ABI, MDexTicket_ADDRESS);
export const contractMDex = new web3.eth.Contract(MDex_ABI, MDex_ADDRESS);
export const contractDoubleBack = new web3.eth.Contract(DOUBLEBACK_ABI, DOUBLEBACK_ADDRESS);

// Função para ouvir a resposta da carteira usando subscribe
export const listenForTransaction = async (transactionHash, callback) => {
  try {
    // Inscreve-se para eventos de transações pendentes
    const subscription = web3.eth.subscribe('newBlockHeaders', async (error, result) => {
      if (error) {
        console.error('Erro ao inscrever-se para novos blocos:', error);
        callback(error);
        return;
      }

      const receipt = await web3.eth.getTransactionReceipt(transactionHash);
      if (receipt) {
        subscription.unsubscribe();
        if (receipt.status) {
          callback(null, receipt); // Transação confirmada
        } else {
          callback(new Error('Transaction failed'), receipt); // Transação falhou
        }
      }
    });
  } catch (error) {
    console.error('Erro ao configurar o ouvinte de transações:', error);
    callback(error);
  }
};

// Função para comprar bilhetes
export const buyTickets = async (ticketIds, sponsorId, value, account, buyer, autoPurchase) => {
  try {
    
    // Obter a taxa de gás atual
    const gasPrice = await web3.eth.getGasPrice();

    // Calcular o limite de gás estimado para a transação
    const gasLimit = await contractMDexTicket.methods.buyTickets(ticketIds, sponsorId, buyer, autoPurchase).estimateGas({
      from: account,
      value: web3.utils.toWei(value.toString(), 'ether'),
    });

    //console.log(gasLimit);

    // Chama a função buyTickets do contrato
    const result = await contractMDexTicket.methods.buyTickets(ticketIds, sponsorId, buyer, autoPurchase).send({
      from: account, // Endereço da carteira
      value: web3.utils.toWei(value.toString(), 'ether'), // Converte o valor para wei
      gas: gasLimit,
      gasPrice: gasPrice,
    });

    // Configura a escuta da transação
    listenForTransaction(result.transactionHash, (error, receipt) => {
      if (error) {
        console.error('Erro na transação:', error);
        enqueueSnackbar('Erro na transação: ' + error.message, { variant: 'error' });
      } else {
        console.log('Transação confirmada:', receipt);
        enqueueSnackbar('Transação confirmada!', { variant: 'success' });
      }
    });

    // Retorna o resultado inicial da transação
    return result;

  } catch (error) {
    console.error("Erro ao comprar bilhetes:", error);
    enqueueSnackbar("Erro ao comprar bilhetes: " + error.message, { variant: 'error' });
    throw error; // Rejeita a promessa com o erro
  }
};

export const setAutoPurchase = async (status, account) => {
  
  try {

    // Obter a taxa de gás atual
    const gasPrice = await web3.eth.getGasPrice();

    // Calcular o limite de gás estimado para a transação
    const gasLimit = await contractMDex.methods.setAutoPurchase(status).estimateGas({
      from: account,
      value: 0,
    });

    //console.log(gasLimit);
    
  const result = await contractMDex.methods.setAutoPurchase(status).send({
    from: account, // Endereço da carteira
    value: 0, // Converte o valor para wei
    gas: gasLimit,
    gasPrice: gasPrice,
  });

  // Configura a escuta da transação
  listenForTransaction(result.transactionHash, (error, receipt) => {
    if (error) {
      console.error('Erro na transação:', error);
      enqueueSnackbar('Auto purchase error: ' + error.message, { variant: 'error' });
    } else {
      console.log('Transação confirmada:', receipt);
      enqueueSnackbar('Automatic repurchase updated!', { variant: 'success' });
    }
  });

  return result;

  } catch (error) {
    console.error("Auto repurchase error", error);
    throw error; // Rejeita a promessa com o erro
  }
  
};





