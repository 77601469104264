import React, { useEffect, useState, useMemo } from "react";
import { Card, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import Table from "examples/Tables/Table";
import { formatDateTime } from "utils/format";
import SearchBar from './SearchBar';
import { getMdexEvents } from "utils/contracts";
import debounce from 'lodash.debounce';
import UserAddressComponent from "./UserAddressComponent";
import { AddressCopy } from "./AddressCopy";
import { formatWei } from "utils/format";

const EventMdexHistory = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    async function fetchData() {
      const events = await getMdexEvents();
      setData(events);
    }

    fetchData();
  }, []);

  // Debounced search handler
  const handleSearch = useMemo(() => debounce((event) => {
    setSearchTerm(event.target.value);
  }, 300), []);

  // Filtrar dados
  const filteredTickets = useMemo(() => {
    return data.filter(item =>
      item.returnValues.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.transactionHash.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const eventStyles = {
    NewUser: { color: 'warning', name: 'New User' },
    UserAddedToMatrix: { color: 'warning', name: 'New user added to Matrix' },
    DirectReferralComission: { color: 'success', name: 'Direct Referral Commission' },
    CommissionPaymentFailed: { color: 'error', name: 'Commission Payment' },
    MatrixCommission: { color: 'success', name: 'Matrix Commission' },
  };

  const EventDescription = ({ event, desc = null }) => {
    const { color, name } = eventStyles[event] || { color: 'white', name: event };
    return (
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="body2" color={color}>
          {name}
        </VuiTypography>
        {desc && (
          <VuiTypography variant="caption" color={color}>
            {desc}
          </VuiTypography>
        )}
      </VuiBox>
    );
  };

  const tableRow = useMemo(() => {
    return filteredTickets.map((item) => ({
      Event:
        <EventDescription event={item.event} desc={item.returnValues.reason} />,
      User: 
      <VuiBox>
                {/* <VuiBadge badgeContent={`ID: ${item.user.id}`} color={'info'} size={'md'} sx={{marginRight: '5px'}} /> */}
                <AddressCopy address={item.returnValues.user}/>
      </VuiBox>,
      TxId: <AddressCopy address={item.transactionHash} txId={true} />,
      Amount: item.returnValues.amount ? formatWei(item.returnValues.amount) : '',
      Level: item.returnValues.level,
      DirectQueue: item.returnValues.totalDirectsQueue,
      Date: formatDateTime(item.returnValues.timestamp),
    }));
  }, [filteredTickets]);

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Last Cryptomarketing events
          </VuiTypography>
        </VuiBox>
        <VuiBox>
          <SearchBar value={searchTerm} onChange={handleSearch} />
        </VuiBox>
      </VuiBox>

      <Grid container>
        {filteredTickets.length > 0 ? (
          <Table
            columns={[
              { name: "Event", align: "left" },
              { name: "User", align: "left" },
              { name: "TxId", align: "left" },
              { name: "Amount", align: "left" },
              { name: "Level", align: "left" },
              { name: "DirectQueue", align: "left" },
              { name: "Date", align: "left" },
            ]}
            rows={tableRow}
          />
        ) : (
          <VuiTypography color='white' variant='button'>Be the first to choose tickets for the open draw.</VuiTypography>
        )}
      </Grid>
    </Card>
  );
};

export default EventMdexHistory;
