/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";


// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";

import ReferralTracking from "layouts/dashboard/components/ReferralTracking";

// React icons
import { IoGlobe, IoTicket, IoWarning } from "react-icons/io5";

import { useLocation, useHistory } from "react-router-dom";
import ReturnRate from "./components/returnRate";
import { getAccountWallet } from "detect";
import { getUserData, getUserByID, getUserTotalTickets } from "utils/contracts";
import VuiAlert from "components/VuiAlert";
import VuiButton from "components/VuiButton";
import { Puff } from "react-loading-icons";
import { getCurrentLottery } from "utils/contracts";
import UserTicketsHistory from "components/UserTicketsHistory";
import UserLottoTickets from "components/UserLottoTickets";
import UserTotalDirects from "components/UserTotalDirects";
import UserTotalMatrix from "components/UserTotalMatrix";
import UserMatrixNavigation from "components/UserMatrixNavigation";
import LevelsUnlock from "components/LevelsUnlock";

import { isZeroAddress } from "utils/format";
import SearchBarAccount from "components/SearchBarAccount";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import DirectReferal from "components/DirectReferal";

function Account() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [account, setAccount] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const { pathname, search } = useLocation();
  const [totalTickets, setTotalTickets] = useState(null);
  const [currentLottery, setCurrentLottery] = useState(null);
  const history = useHistory();

  useEffect(() => {

    async function fetchData() {
        
        const queryParams = new URLSearchParams(search);
        const uId = queryParams.get('uId');
        const Adr = queryParams.get('a');

        let accountAddress; // Declare accountAddress aqui
        let accountData;
        let SetuserData;
        if (uId){
            accountData = await getUserByID(uId);
            accountAddress = accountData;  
            SetuserData = await getUserData(accountAddress);

        }
        else if(Adr){
            accountData = await getUserData(a);
            accountAddress = accountData.user;  
            SetuserData = accountAddress;
            console.log(Adr);

        }else{
            accountData = await getAccountWallet();
            accountAddress = accountData.account;
            setAccount(accountAddress);
            SetuserData = await getUserData(accountAddress);
        }

        setUserData(SetuserData);

        if (!isZeroAddress(SetuserData.user)){
            setUserId(SetuserData.id);
            const currentLottoData = await getCurrentLottery();
            setCurrentLottery(currentLottoData);
            const totalTicketData = await getUserTotalTickets(accountAddress, currentLottoData.id);
            setTotalTickets(totalTicketData);
        }else{
            //setAccount(null);
            //enqueueSnackbar('Account not found: '+accountAddress, { variant: 'error' });
        }

    }

    fetchData();
    }, [search, userId]);

  const NotFound = ({ac, user}) => {
    
    return user ? 
    
    <VuiBox flexDirection="column" p={2} m={1} textAlign='center'>
      
    {
      ac ? 
        <VuiBox sx={{margin: '15px'}} flexDirection={'column'}>
          <VuiTypography color='white' variant='h2'> You don't have an account yet</VuiTypography>
          <VuiTypography color='white' variant='h6' mb={'10px'}> To have an account is very simple, just purchase your first Lucky Number Bet ticket</VuiTypography>
          <NavLink to='/buy'>
							<VuiButton color='success'  variant='contained' mt={'10px'} size={'medium'}>Buy Ticket Now</VuiButton>
					</NavLink>

        </VuiBox>
      : 
        <VuiBox>
          <VuiTypography color='white' variant='h2'> User Not Found</VuiTypography>
        </VuiBox>
      }
    
    </VuiBox>

  :

    <Puff />
  }

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      

{userData ? 
  !isZeroAddress(userData.user) && !isZeroAddress(account) ? 

    <VuiBox py={3}>
     <VuiBox mb={3}>
       <Grid container spacing={3}>
       
         <Grid item xs={12} lg={5} xl={4}>
           <WelcomeMark 
           id={userData ? userData.id : null} 
           nickname={userData ? userData.nickname : null} 
           sponsor={userData ? userData.sponsor : null} 
           address={userData ? userData.user : null} 
           isActive={userData ? userData.isActive : false}
           autoPurchase={userData ? userData.autoPurchase : false}
           />
         </Grid>
         <Grid item xs={12} lg={6} xl={4}>
           <ReturnRate userData={userData ? userData : null} />
         </Grid>
         <Grid item xs={12} lg={6} xl={4}>
           <ReferralTracking address={userData ? userData.user : null} userData={userData ? userData : null} />
         </Grid>
       </Grid>

       {userData ? !userData.Matrix.isActive ?
        <Grid item xs={12} mt={3}>
            <VuiAlert color="error">
              <IoWarning color="white" />
              <VuiBox>
                <VuiTypography color={'white'} variant="button">This account is not active and therefore will not receive Spilling Matrix earnings.</VuiTypography>
              </VuiBox>
          </VuiAlert>
        </Grid>
        :<></> : <></>
        }
        
     </VuiBox>

     <VuiBox mb={3}>
       <Grid container spacing={3}>
         <Grid item xs={12} xl={4}>
           <UserLottoTickets lotto={currentLottery ? currentLottery : null} tickets={totalTickets ? totalTickets : []} />
         </Grid>
         <Grid item xs={12} xl={4}>
           <UserTotalDirects id={userData ? userData.id : 0} total={userData ? Number(userData.profitDirect) : 0}  />
         </Grid>
         <Grid item xs={12} xl={4}>
           <UserTotalMatrix id={userData ? userData.id : 0} total={userData ? Number(userData.Matrix.profit) : 0} />
         </Grid>

        
       </Grid>
     </VuiBox>

     <Grid container spacing={3}>
         <Grid item xs={12} xl={6}>
          <UserTicketsHistory userAddress={userData ? userData.user : null} lottoId={currentLottery ? currentLottery.id : null} tickets={totalTickets ? totalTickets : []} />
         </Grid>

         <Grid item xs={12} xl={6}>
          <LevelsUnlock address={userData ? userData.user : null} levels={10} />
         </Grid>


         <Grid item xs={12}>
          <UserMatrixNavigation downlines={userData ? userData.Matrix.downlines : []} user={userData ? userData : null} />
         </Grid>

         <Grid item xs={12} xl={12}>
          <DirectReferal address={userData ? userData.user : null} />
         </Grid>

    </Grid>

    </VuiBox>
  
  : 
  
  <NotFound ac={account} user={userData} />
     
:
  <NotFound ac={account} user={userData}/>
}

  <SearchBarAccount /> 
   
      <Footer />
    </DashboardLayout>
  );
}

export default Account;
